"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PremiumKeyEnum = exports.StoriesOptionFilters = exports.ReportStatusEnum = exports.PastClientTypeEnum = exports.MailingListStatusEnum = exports.ActionTypeEnum = exports.SortByEnum = exports.SendActionEnum = exports.MailingListTypeEnum = exports.FilterTagTypeEnum = exports.OrderByMailingListEnum = exports.AGENT_FALLBACK_VARS = exports.EXCLUDED_PROPERTIES = exports.FALLBACK_PROPERTIES = exports.MLS_FALLBACK_PROPERTIES = exports.DEFAULT_FALLBACK_PROPERTIES = exports.MLS_STATUS_PREFIX = exports.NEW_MY_LISTING_PREFIX_FROM_ADMIN = void 0;
const models_1 = require("../postalytics/models");
const { uniqBy } = require('lodash');
/* I assume that this one also is the specical signature to recognize New My Listing variables from Admin Django.
 *   Because variables from Admin don't have Mailing List name
 */
exports.NEW_MY_LISTING_PREFIX_FROM_ADMIN = 'ml';
exports.MLS_STATUS_PREFIX = {
    [models_1.MLSStatusEnums.ACTIVE_UNDER_CONTRACT]: 'active_under_contract',
    [models_1.MLSStatusEnums.COMING_SOON]: 'coming_soon',
    [models_1.MLSStatusEnums.WITHDRAWN]: 'withdrawn',
    [models_1.MLSStatusEnums.INACTIVE]: 'inactive',
    [models_1.MLSStatusEnums.PENDING]: 'pending',
    [models_1.MLSStatusEnums.EXPIRED]: 'expired',
    [models_1.MLSStatusEnums.ACTIVE]: 'active',
    [models_1.MLSStatusEnums.CLOSED]: 'sold',
    [models_1.MLSStatusEnums.CANCELED]: null,
    [models_1.MLSStatusEnums.DELETE]: null,
    [models_1.MLSStatusEnums.HOLD]: null,
    [models_1.MLSStatusEnums.INCOMPLETE]: null,
};
exports.DEFAULT_FALLBACK_PROPERTIES = [
    'first_name',
    'last_name',
    'full_name',
    'address_1',
    'city',
    'state',
    'zip',
    'notice_number',
    'owner_city',
    'owner_state',
    'owner_zip',
    'home_type',
    'street_name_only_in_mailling_list',
    'street_number_and_name_only_in_mailling_list',
    'street_name_only_for_homeowner',
    'street_number_and_name_for_home_owner',
    'years_since_purchased',
    'last_sold_date',
    'last_sold_price',
    'avm_value',
    'assessed_home_value',
    'stories',
    'assessed_or_active_value',
    'average_years_owned',
    'square_feet',
    'num_of_bathrooms',
    'num_of_bedrooms',
    'home_equity',
    'home_equity_percentage',
    'homeowner_industry',
    'turn_over_rate',
    'year_built',
    'remodeling_value',
    'neighborhood',
    'remodeling_value_of_home',
    'remodel_upside_of_home',
    'listing_image',
    'listing_image_1',
    'listing_image_2',
    'listing_image_3',
    'listing_image_4',
    'listing_image_5',
    'street_view_image',
    'broker_logo',
    'agent_logo',
    'agent_image',
];
exports.MLS_FALLBACK_PROPERTIES = [
    'street_name',
    'street_number_and_name',
    'city',
    'neighborhood',
    'state',
    'zip',
    'num_of_bedrooms',
    'num_of_bathrooms',
    'square_feet',
    'last_sold_price',
    'listing_agent_name_and_license',
    'equity_percent',
    'stories',
    'dom',
    'listed_price',
    'last_sold_date',
    'year_built',
    'image_1',
    'image_2',
    'image_3',
    'image_4',
    'image_5',
    'image_6',
    'average_years_owned',
    'turnover_rate',
    'years_owned',
    'estimated_value',
    'mortgage_type',
    'interest_rate',
    'loan_value',
    'features',
    'first_two_lines_mls_description',
    'listing_price_per_square_foot',
    'street_view_image',
    'home_owner_company',
];
exports.FALLBACK_PROPERTIES = [
    ...uniqBy(exports.DEFAULT_FALLBACK_PROPERTIES.concat(exports.MLS_FALLBACK_PROPERTIES), (v) => v),
];
exports.EXCLUDED_PROPERTIES = ['listing_image'];
exports.AGENT_FALLBACK_VARS = [
    'agent_name',
    'agent_mail',
    'agent_dre',
    'agent_phone_number',
    'agent_email',
    'listing_agent_name_and_license',
    'agent_office_address',
    'date_issued',
    'current_year',
    'date_expire',
];
var OrderByMailingListEnum;
(function (OrderByMailingListEnum) {
    OrderByMailingListEnum["CREATED_DESC"] = "-created";
    OrderByMailingListEnum["CREATED_ASC"] = "created";
})(OrderByMailingListEnum = exports.OrderByMailingListEnum || (exports.OrderByMailingListEnum = {}));
var FilterTagTypeEnum;
(function (FilterTagTypeEnum) {
    FilterTagTypeEnum["SEGMENT"] = "segment";
    FilterTagTypeEnum["ALL"] = "all";
})(FilterTagTypeEnum = exports.FilterTagTypeEnum || (exports.FilterTagTypeEnum = {}));
var MailingListTypeEnum;
(function (MailingListTypeEnum) {
    MailingListTypeEnum["All"] = "ALL";
    MailingListTypeEnum["Import"] = "IMPORT";
    MailingListTypeEnum["Draw"] = "DRAW";
    MailingListTypeEnum["Past_Client"] = "PAST_CLIENT";
    MailingListTypeEnum["Lead"] = "LEAD";
    MailingListTypeEnum["My_Listing"] = "MY_LISTING";
})(MailingListTypeEnum = exports.MailingListTypeEnum || (exports.MailingListTypeEnum = {}));
var SendActionEnum;
(function (SendActionEnum) {
    SendActionEnum["SendEmail"] = "send_email";
    SendActionEnum["SendMail"] = "send_mail";
    SendActionEnum["SendSMS"] = "send_sms";
})(SendActionEnum = exports.SendActionEnum || (exports.SendActionEnum = {}));
var SortByEnum;
(function (SortByEnum) {
    SortByEnum["LAST_NOTIFICATION_AT"] = "last_notification_at";
    SortByEnum["AVM_VALUE"] = "avm_value";
    SortByEnum["LAST_SOLD_DATE"] = "last_sold_date";
    SortByEnum["BATHROOMS"] = "bathrooms";
    SortByEnum["BEDROOMS"] = "bedrooms";
    SortByEnum["EQUITY"] = "equity";
    SortByEnum["AVG_YEARS_OWNED"] = "avg_years_owned";
    SortByEnum["YEARS_OWNED"] = "years_owned";
    SortByEnum["SQ_FEET"] = "sqft";
    SortByEnum["PREMIUM_LATEST_FILE_DATE_NOTICE"] = "premium_latest_file_date_notice";
    SortByEnum["PREMIUM_LATEST_FILE_DATE_AFFIDAVITS_OF_DEATH"] = "premium_latest_file_date_affidavits_of_death";
    SortByEnum["PREMIUM_LATEST_FILE_DATE_DIVORCEES"] = "premium_latest_file_date_divorcees";
    SortByEnum["INTEREST_RATES"] = "interest_rates";
    SortByEnum["DAYS_ON_MARKET"] = "days_on_market";
    SortByEnum["PRICE_PER_SQUARE_FOOT"] = "price_per_square_foot";
})(SortByEnum = exports.SortByEnum || (exports.SortByEnum = {}));
var ActionTypeEnum;
(function (ActionTypeEnum) {
    ActionTypeEnum["WebFormFilled"] = "web_form_filled";
    ActionTypeEnum["ScannedQRCode"] = "scanned_qr_code";
    ActionTypeEnum["RecievedSMS"] = "received_sms";
    ActionTypeEnum["RecievedEmail"] = "received_email";
    ActionTypeEnum["RecievedCall"] = "received_call";
    ActionTypeEnum["Chatbot"] = "chatbot";
    ActionTypeEnum["CalendarAppointment"] = "calendar_appointment";
})(ActionTypeEnum = exports.ActionTypeEnum || (exports.ActionTypeEnum = {}));
var MailingListStatusEnum;
(function (MailingListStatusEnum) {
    MailingListStatusEnum["Pending"] = "PENDING";
    MailingListStatusEnum["InProgress"] = "IN-PROGRESS";
    MailingListStatusEnum["Completed"] = "COMPLETED";
    MailingListStatusEnum["Failed"] = "FAILED";
})(MailingListStatusEnum = exports.MailingListStatusEnum || (exports.MailingListStatusEnum = {}));
var PastClientTypeEnum;
(function (PastClientTypeEnum) {
    PastClientTypeEnum["Listing"] = "LISTING";
    PastClientTypeEnum["Buyer"] = "BUYER";
    PastClientTypeEnum["DoubleEnded"] = "DOUBLE_ENDED";
})(PastClientTypeEnum = exports.PastClientTypeEnum || (exports.PastClientTypeEnum = {}));
var ReportStatusEnum;
(function (ReportStatusEnum) {
    ReportStatusEnum["AgentDataNotFound"] = "Agent data not found";
    ReportStatusEnum["GeneratingReport"] = "Generating report";
    ReportStatusEnum["Completed"] = "COMPLETED";
    ReportStatusEnum["Initiating"] = "initiating";
    ReportStatusEnum["Initiated"] = "initiated";
})(ReportStatusEnum = exports.ReportStatusEnum || (exports.ReportStatusEnum = {}));
var StoriesOptionFilters;
(function (StoriesOptionFilters) {
    StoriesOptionFilters["UNCLASSIFIED"] = "0,0";
    StoriesOptionFilters["ONLY_ONE"] = "1,1";
    StoriesOptionFilters["OVER_TWO"] = "2,2";
})(StoriesOptionFilters = exports.StoriesOptionFilters || (exports.StoriesOptionFilters = {}));
var PremiumKeyEnum;
(function (PremiumKeyEnum) {
    PremiumKeyEnum["PREMIUM_DATA_EMAIL"] = "email";
    PremiumKeyEnum["PREMIUM_DATA_PHONE"] = "phone";
    PremiumKeyEnum["PREMIUM_DATA_NOTICE"] = "notice";
    PremiumKeyEnum["PREMIUM_DATA_AFFIDAVITS_OF_DEATH"] = "affidavits_of_death";
    PremiumKeyEnum["PREMIUM_DATA_INTEREST_RATE_TYPE"] = "interest_rate_type";
    PremiumKeyEnum["PREMIUM_DATA_LOAN_TYPE"] = "loan_type";
    PremiumKeyEnum["PREMIUM_DATA_LOAN_AMOUNT"] = "loan_amount";
    PremiumKeyEnum["PREMIUM_DATA_ESTIMATED_INTEREST_RATE"] = "estimated_interest_rate";
    PremiumKeyEnum["PREMIUM_DATA_DIVORCEES"] = "divorcees";
})(PremiumKeyEnum = exports.PremiumKeyEnum || (exports.PremiumKeyEnum = {}));
