"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariableTool = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
const moment = require('moment');
const { uniqBy, pick, pickBy, omit, reduce } = require('lodash');
const models_1 = require("./../../postgrid/models");
const models_2 = require("../../mailing/models");
const currency_1 = require("../../../utils/currency");
const number_1 = require("../../../utils/number");
const object_1 = require("../../../utils/object");
const phone_1 = require("../../../utils/phone");
const models_3 = require("./models");
const caching_1 = require("../../caching");
class VariableTool {
    constructor(client) {
        this.client = client;
        this.staleTimes = {
            getNewestMlsMailings: 5,
            listAddressFromMailingIds: 5,
            getGlobalFallbacks: 60,
        };
        this.statuses = Object.values(models_2.MLS_STATUS_PREFIX)
            .filter((v) => !!v)
            .sort((a, b) => { var _a, _b; return ((_a = (b || '')) === null || _a === void 0 ? void 0 : _a.length) - ((_b = (a || '')) === null || _b === void 0 ? void 0 : _b.length); });
        this.getFallbackVariableWithMailingData = (v, options) => {
            var _a, _b;
            const isNotGetExtraData = options.isNotGetExtraData;
            const templateFallback = options.templateFallback;
            const realDataFallback = this.handleFallbackRules(v, Object.assign(Object.assign({}, options), { isNotGetExtraData }));
            const allFallbacks = Object.assign({}, pick(templateFallback === null || templateFallback === void 0 ? void 0 : templateFallback.default_fallbacks, models_2.AGENT_FALLBACK_VARS));
            const extraDataMailingKeys = Object.keys(v.extra_data || {});
            const mlsDataKeys = [...uniqBy(Object.keys(((_a = options === null || options === void 0 ? void 0 : options.templateFallback) === null || _a === void 0 ? void 0 : _a.mls_data) || {}), (v) => v)];
            const mlsMailingListDataKeys = [
                ...uniqBy(Object.keys(((_b = options === null || options === void 0 ? void 0 : options.templateFallback) === null || _b === void 0 ? void 0 : _b.mailing_list_data) || {}), (v) => v),
            ];
            const regex = this.getPrefixRegex();
            const allKeys = [
                ...uniqBy(this.allVars
                    .concat(models_2.FALLBACK_PROPERTIES)
                    // Add Extra Data Keys
                    .concat(extraDataMailingKeys), (v) => v),
            ]
                .map((v) => v.replace(regex, ''))
                .filter((v) => !models_2.EXCLUDED_PROPERTIES.includes(v))
                // Add MLS Address keys with prefixed
                .concat(mlsDataKeys)
                // Add MLS Address keys without prefixed
                .concat(mlsMailingListDataKeys);
            allKeys.forEach((property) => {
                var _a;
                const realVarData = (realDataFallback === null || realDataFallback === void 0 ? void 0 : realDataFallback[property]) || '';
                const globalVarData = ((_a = templateFallback === null || templateFallback === void 0 ? void 0 : templateFallback.default_fallbacks) === null || _a === void 0 ? void 0 : _a[property]) || '';
                // ADDRESSES PREFIXED KEYS
                if (mlsDataKeys.some((v) => v === property)) {
                    allFallbacks[property] = globalVarData;
                    // For mls_data, we had handled to push all data of variable type to default_fallbacks for using all in globally
                    this.log('prefixes_vars===', property, globalVarData); // mlsDataKeys is Real data
                }
                else if (mlsMailingListDataKeys.some((v) => v === property)) {
                    allFallbacks[property] = globalVarData;
                    // For mailing_list_data, we had handled to push all data of variable type to default_fallbacks for using all in globally
                    this.log('prefixes_vars=mailing_list==', property, globalVarData); // mlsMailingListDataKeys is Real data
                }
                else {
                    // Handle real data. Priority for real-data. If the fallback is still empty. Need to use global fallback
                    allFallbacks[property] = realVarData;
                }
            });
            models_2.EXCLUDED_PROPERTIES.forEach((key) => {
                var _a, _b;
                if (isNotGetExtraData) {
                    allFallbacks[key] = '';
                    return;
                }
                const customFallbacks = (v === null || v === void 0 ? void 0 : v.extra_data) ? ((_a = v === null || v === void 0 ? void 0 : v.extra_data) === null || _a === void 0 ? void 0 : _a[key]) || [] : [];
                const templateFallbacks = (templateFallback === null || templateFallback === void 0 ? void 0 : templateFallback.default_fallbacks) ? ((_b = templateFallback === null || templateFallback === void 0 ? void 0 : templateFallback.default_fallbacks) === null || _b === void 0 ? void 0 : _b[key]) || []
                    : [];
                allFallbacks[key] = [
                    ...uniqBy([
                        ...customFallbacks.map((e) => (typeof e === 'string' ? e : e === null || e === void 0 ? void 0 : e.url)),
                        ...templateFallbacks.map((e) => (typeof e === 'string' ? e : e === null || e === void 0 ? void 0 : e.url)),
                    ], (v) => v),
                ];
            });
            return allFallbacks;
        };
        this.prefixKeys = this.getAllKeyPrefixes();
        this.allVars = [];
        this.caching = new caching_1.Caching();
    }
    log(...params) {
        if (process.env.NODE_ENV === 'development')
            console.log(...params);
    }
    getPrefixRegex() {
        const prefixes = this.prefixKeys;
        const regexString = `^(${prefixes.join('|')})`;
        return new RegExp(regexString, 'g');
    }
    getFallbackVariableWithoutMailingData(agentInfo, templateFallback) {
        return __awaiter(this, void 0, void 0, function* () {
            const fallbackDefault = object_1.cloneDeep(templateFallback || {});
            fallbackDefault.default_fallbacks = Object.assign({}, (fallbackDefault.default_fallbacks || {}));
            // Setup default fallbacks for Agent Info
            const agentFallbacks = this.handleFallbackRules(null, { agentInfo });
            // Handle MLS variables with selected address === mls_data ===
            const updatedTemplateFallbackWithSelectedAddress = yield this.convertUserPostgridMLSVariablesWithSelectedAddress(fallbackDefault, fallbackDefault.type);
            this.log('updatedTemplateFallbackWithSelectedAddress===', updatedTemplateFallbackWithSelectedAddress);
            fallbackDefault.default_fallbacks = Object.assign(Object.assign({}, fallbackDefault.default_fallbacks), updatedTemplateFallbackWithSelectedAddress.default_fallbacks);
            // Handle MLS variables without selected address === mailing_list_data ===
            const updatedTemplateFallbackLatestHome = yield this.convertUserPostgridMLSVariablesWithLatestHomeOfMailingList(fallbackDefault, fallbackDefault.type);
            this.log('updatedTemplateFallbackLatestHome===', updatedTemplateFallbackLatestHome);
            fallbackDefault.default_fallbacks = Object.assign(Object.assign({}, fallbackDefault.default_fallbacks), updatedTemplateFallbackLatestHome.default_fallbacks);
            // Merge all fallbacks
            fallbackDefault.default_fallbacks = Object.assign(Object.assign({}, fallbackDefault.default_fallbacks), agentFallbacks);
            this.log('agent_final===', fallbackDefault);
            return fallbackDefault;
        });
    }
    formatGlobalVariable(data) {
        const variables = object_1.cloneDeep(data);
        // Currency format
        const currencyKeysNot$ = ['square_feet'];
        Object.keys(variables).map((key) => {
            var _a;
            const variable = variables[key];
            if (currencyKeysNot$.some((v) => key.endsWith(v))) {
                const value = typeof variable === 'string'
                    ? variable.replace(/\D/g, '')
                    : typeof variable === 'number'
                        ? variable.toString()
                        : null;
                variables[key] = currency_1.formatCurrencySymbol((_a = Number(value)) === null || _a === void 0 ? void 0 : _a.toString(), '', true);
            }
        });
        return variables;
    }
    getMailingsWithFilledFallbackVariable(agentInfo, rawTemplateFallback, mailings, type = models_1.CampaignPreviewType.POSTGRID) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!(mailings === null || mailings === void 0 ? void 0 : mailings.length))
                return mailings || [];
            const newTemplateFallback = yield this.getFallbackVariableWithoutMailingData(agentInfo, rawTemplateFallback);
            const convertedMailings = object_1.cloneDeep(mailings);
            const prefixedKeys = uniqBy([
                ...Object.keys((rawTemplateFallback === null || rawTemplateFallback === void 0 ? void 0 : rawTemplateFallback.default_fallbacks) || {}),
                ...Object.keys((rawTemplateFallback === null || rawTemplateFallback === void 0 ? void 0 : rawTemplateFallback.mls_data) || {}),
                ...Object.keys((rawTemplateFallback === null || rawTemplateFallback === void 0 ? void 0 : rawTemplateFallback.mailing_list_data) || {}),
            ]);
            const globalFallbackDataAllVars = yield this.getGlobalFallbacks(prefixedKeys, agentInfo.agent_id);
            for (let i = 0; i < convertedMailings.length; i++) {
                const item = convertedMailings[i];
                const homeFallback = this.getFallbackVariableWithMailingData(item, {
                    templateFallback: newTemplateFallback,
                    isSendOwnerAddress: item.is_send_owner_address,
                    agentInfo,
                    type,
                });
                const emptyValuesKeys = Object.keys(pickBy(homeFallback, (v) => v === null || v === '' || (Array.isArray(v) && !v.length)));
                const pickedGlobalFallbackOfAllVars = pick(globalFallbackDataAllVars, emptyValuesKeys);
                const clonedHomeFallbackHaveValues = omit(homeFallback, emptyValuesKeys);
                this.log('getMailingsWithFilledFallbackVariable__improvement', clonedHomeFallbackHaveValues, pickedGlobalFallbackOfAllVars, emptyValuesKeys);
                item.fallback = Object.assign(Object.assign({}, clonedHomeFallbackHaveValues), pickedGlobalFallbackOfAllVars);
                if (!rawTemplateFallback.isReplaceEmptyValue) {
                    const item_fallback = object_1.cloneDeep(item.fallback);
                    item.fallback = reduce(prefixedKeys, (result, key) => {
                        result[key] = item_fallback[key] || '';
                        return result;
                    }, {});
                }
                item.globalDataKeys = Object.keys(pickBy(pickedGlobalFallbackOfAllVars, (v) => !!v));
                this.log('getMailingsWithFilledFallbackVariable__improvement_2', item.globalDataKeys, item.fallback);
            }
            return convertedMailings;
        });
    }
    extractVariablesFromHtmlString(html) {
        // Define the regular expression pattern for finding variables inside double curly braces
        const pattern = /{{\s*([^{}]+)\s*}}/g;
        // Extract matches from the HTML string
        const matches = html.match(pattern);
        // Filter out duplicates and return the array of variables
        const uniqueVariables = [...uniqBy(matches, (v) => v)];
        return uniqueVariables.map((v) => v.replace('{{', '').replace('}}', ''));
    }
    getAllVariablesFromSectionVars(sectionVars) {
        const variables = [];
        const sections = Object.keys(sectionVars);
        sections.forEach((key) => {
            switch (key) {
                case 'not_my_listings':
                    sectionVars === null || sectionVars === void 0 ? void 0 : sectionVars['mls_status'].forEach((mls_status) => {
                        var _a;
                        const childVars = (((_a = sectionVars === null || sectionVars === void 0 ? void 0 : sectionVars[key]) === null || _a === void 0 ? void 0 : _a.mls_status) || []).map((value) => `nml_${mls_status}_${value}`);
                        variables.push(...childVars);
                    });
                    break;
                case 'my_listings':
                case 'listing_of_my_mailing_list':
                    sectionVars === null || sectionVars === void 0 ? void 0 : sectionVars['mls_status'].forEach((mls_status) => {
                        var _a;
                        const childVars = (((_a = sectionVars === null || sectionVars === void 0 ? void 0 : sectionVars[key]) === null || _a === void 0 ? void 0 : _a.mls_status) || []).map((value) => `${mls_status}_${value}`);
                        variables.push(...childVars);
                    });
                    break;
                case 'past_clients':
                    const pastClientTypeKeys = Object.keys(sectionVars[key]);
                    pastClientTypeKeys.forEach((childKey) => {
                        var _a, _b;
                        const prefix = `pc_${childKey}`;
                        const childVars = (((_b = (_a = sectionVars === null || sectionVars === void 0 ? void 0 : sectionVars[key]) === null || _a === void 0 ? void 0 : _a[childKey]) === null || _b === void 0 ? void 0 : _b.original_vars) || []).map((value) => `${prefix}_${value}`);
                        variables.push(...childVars);
                    });
                    break;
                case 'mls_status':
                    break;
                default:
                    variables.push(...(sectionVars[key].original_vars || []));
                    break;
            }
        });
        if (!this.allVars.length) {
            this.allVars = variables;
        }
        return variables;
    }
    getFormatType(key, value) {
        if (models_3.currencyVarKeys.some((v) => key.endsWith(v))) {
            return models_3.varRegex.currencyRegexPattern.test(value) && 'currency';
        }
        if (models_3.numericVarKeys.some((v) => key.endsWith(v))) {
            return models_3.varRegex.numericRegexPattern.test(value) && 'numeric';
        }
        if (models_3.dateTimeVarKeys.some((v) => key.endsWith(v))) {
            // Parse the value using Moment.js with the specified format
            const parsedDate = moment(value, models_3.dateTime.dateTimeUSFormat, true);
            // Check if the parsed date is valid
            return parsedDate.isValid() && 'dateTime';
        }
        return true;
    }
    handleFormatDateTime(key, value) {
        if (key.endsWith('last_sold_date')) {
            return moment(value, models_3.dateTime.lastSoldDateDataFormat).format(models_3.dateTime.dateTimeUSFormat);
        }
        return moment(value, models_3.dateTime.dateTimeUSFormat).format(models_3.dateTime.dateTimeUSFormat);
    }
    formatFallbackVariables(key, value) {
        const formatType = this.getFormatType(key, value);
        switch (formatType) {
            case 'currency':
                return currency_1.formatCurrencySymbol(currency_1.removeCurrencyFormat(value), 'USD', true);
            case 'numeric':
                return number_1.formatCommas(number_1.removeEquityFormat(value));
            case 'dateTime':
                return this.handleFormatDateTime(key, value);
            case false:
                return null;
            default:
                return value;
        }
    }
    // Define the main function to remove formatting based on variable types
    removeFormatFallbackVariables(fallbackDefault) {
        const keys = Object.keys(fallbackDefault);
        keys.forEach((k) => {
            const value = fallbackDefault[k];
            // Check if the input value is a string
            if (typeof value === 'string') {
                // Check specific formatting rules for phone numbers
                if (k.includes(models_3.includedPhoneKeyVariables)) {
                    const formattedPhone = phone_1.removePhoneNumberFormat(value);
                    fallbackDefault[k] = formattedPhone;
                }
                // Check specific formatting rules for currency values
                else if (models_3.includedCurrencyKeyVariables.some((currencyKey) => k.includes(currencyKey))) {
                    const formattedCurrency = currency_1.removeCurrencyFormat(value);
                    fallbackDefault[k] = isNaN(formattedCurrency) ? value : formattedCurrency;
                }
                // Check specific formatting rules for equity values
                else if (k.includes(models_3.includedEquityKeyVariables)) {
                    const formattedEquity = number_1.removeEquityFormat(value);
                    fallbackDefault[k] = isNaN(formattedEquity) ? value : formattedEquity;
                }
                else {
                    const isContainsNonNumeric = /[^0-9.]/.test(value);
                    const numberValue = parseFloat(value);
                    fallbackDefault[k] = isContainsNonNumeric ? value : numberValue;
                }
            }
            // If the input value is a number, keep it as is
            else if (typeof value === 'number') {
                // Keep the number as is
            }
            // For other types, keep the original value
            else {
                // For other types, keep the original value
            }
        });
    }
    getAllKeyPrefixes() {
        // My Listing from FE side - not have ml_
        const myListingMlsStatusKeys = this.statuses.map((value) => `${value}_`.toLowerCase());
        // Not My Listing from FE side
        const notMyListingMlsStatusKeys = this.statuses.map((value) => `nml_${value}_`.toLowerCase());
        // Past Client without mls_status in variable (old flow of variable). Ensure that all old template still work well.
        const pastClientWithoutMLSStatusKeys = Object.values(models_2.PastClientTypeEnum).map((v) => `pc_${v}_`.toLowerCase());
        // Past Client with mls_status in variable (old flow of variable).
        const pastClientWithMLSStatusKeys = (() => {
            const keys = [];
            Object.values(models_2.PastClientTypeEnum).map((type) => {
                this.statuses.map((status) => {
                    keys.push(`pc_${type}_${status}_`.toLowerCase());
                });
            });
            return keys;
        })();
        return [
            ...notMyListingMlsStatusKeys,
            ...myListingMlsStatusKeys,
            ...pastClientWithoutMLSStatusKeys,
            ...pastClientWithMLSStatusKeys,
        ].sort((a, b) => b.length - a.length);
    }
    checkValidVarFormat(key, value) {
        const formatType = this.getFormatType(key, value);
        return !!formatType;
    }
    handleFallbackRules(v, options) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
        const { templateFallback, agentInfo, isNotGetExtraData } = options;
        // isSendOwnerAddress = true,
        const fallbacks = object_1.cloneDeep(templateFallback === null || templateFallback === void 0 ? void 0 : templateFallback.default_fallbacks);
        const noneAvailableValues = ['', null, undefined];
        // Agent Rules
        if (agentInfo) {
            fallbacks.agent_name = (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.name) ? agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.name : (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.first_name) || (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.last_name)
                ? `${(agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.first_name) || ''} ${(agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.last_name) || ''}`.trim()
                : '';
            fallbacks.agent_mail = (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.email) || '';
            fallbacks.agent_dre = (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.dre) || '';
            fallbacks.agent_phone_number = (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.phone) ? phone_1.removeCountryCode(phone_1.formatPhoneNumber(agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.phone)) : '';
            fallbacks.agent_email = (agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.email) ? agentInfo === null || agentInfo === void 0 ? void 0 : agentInfo.email : '';
            fallbacks.listing_agent_name_and_license =
                fallbacks.agent_name && fallbacks.agent_dre
                    ? `${fallbacks.agent_name || ''} (${fallbacks.agent_dre || ''})`.trim()
                    : '';
            fallbacks.agent_logo = agentInfo.logo;
            fallbacks.agent_image = agentInfo.avatar;
            fallbacks.broker_logo = agentInfo.broker_logo;
        }
        fallbacks.date_issued = moment().format(models_3.dateTime.dateTimeUSFormat);
        fallbacks.current_year = moment().format('YYYY');
        fallbacks.date_expire = moment().add(models_3.dateTime.dateExpiredMonth, 'months').format(models_3.dateTime.dateTimeUSFormat);
        if (v === null)
            return fallbacks;
        // Mailing Rules
        fallbacks.owner_first_name = (v === null || v === void 0 ? void 0 : v.owner_first_name) || '';
        fallbacks.owner_last_name = (v === null || v === void 0 ? void 0 : v.owner_last_name) || '';
        fallbacks.owner_full_name =
            (v === null || v === void 0 ? void 0 : v.owner_first_name) || (v === null || v === void 0 ? void 0 : v.owner_last_name)
                ? `${(v === null || v === void 0 ? void 0 : v.owner_first_name) || ''} ${(v === null || v === void 0 ? void 0 : v.owner_last_name) || ''}`.trim()
                : '';
        fallbacks.owner_address_1 = (v === null || v === void 0 ? void 0 : v.owner_address) || '';
        fallbacks.owner_notice_number = ((v === null || v === void 0 ? void 0 : v.owner_address) || '').split(' ').join('').toUpperCase() || '';
        fallbacks.first_name = (v === null || v === void 0 ? void 0 : v.first_name) || '';
        fallbacks.last_name = (v === null || v === void 0 ? void 0 : v.last_name) || '';
        fallbacks.full_name = (v === null || v === void 0 ? void 0 : v.first_name) || (v === null || v === void 0 ? void 0 : v.last_name) ? `${(v === null || v === void 0 ? void 0 : v.first_name) || ''} ${(v === null || v === void 0 ? void 0 : v.last_name) || ''}`.trim() : '';
        fallbacks.address_1 = (v === null || v === void 0 ? void 0 : v.address) || '';
        fallbacks.notice_number = ((_a = ((v === null || v === void 0 ? void 0 : v.address) || '')) === null || _a === void 0 ? void 0 : _a.split(' ').join('').toUpperCase()) || '';
        fallbacks.owner_city = (v === null || v === void 0 ? void 0 : v.owner_city) || '';
        fallbacks.owner_state = (v === null || v === void 0 ? void 0 : v.owner_state) || '';
        fallbacks.owner_zip = (v === null || v === void 0 ? void 0 : v.owner_zip_code) || '';
        fallbacks.city = (v === null || v === void 0 ? void 0 : v.city) || '';
        fallbacks.state = (v === null || v === void 0 ? void 0 : v.state) || '';
        fallbacks.zip = (v === null || v === void 0 ? void 0 : v.zip_code) || '';
        fallbacks.street_name_only_in_mailling_list = (v === null || v === void 0 ? void 0 : v.route) || '';
        fallbacks.street_number_and_name_only_in_mailling_list = (v === null || v === void 0 ? void 0 : v.address) || '';
        fallbacks.street_name_only_for_homeowner = (v === null || v === void 0 ? void 0 : v.owner_route) || '';
        fallbacks.street_number_and_name_for_home_owner = (v === null || v === void 0 ? void 0 : v.owner_address) || '';
        // Variables address that named with many ways
        fallbacks.street_name_only = (v === null || v === void 0 ? void 0 : v.route) || '';
        fallbacks.owner_street_name_only = (v === null || v === void 0 ? void 0 : v.owner_route) || '';
        fallbacks.street_number_and_name_only_in_mailling_list = (v === null || v === void 0 ? void 0 : v.address) || '';
        fallbacks.owner_street_number_and_name_only_in_mailling_list = (v === null || v === void 0 ? void 0 : v.owner_address) || '';
        fallbacks.street_name = (v === null || v === void 0 ? void 0 : v.route) || '';
        fallbacks.owner_street_name = (v === null || v === void 0 ? void 0 : v.owner_route) || '';
        fallbacks.street_number_and_name = (v === null || v === void 0 ? void 0 : v.address) || '';
        fallbacks.owner_street_number_and_name = (v === null || v === void 0 ? void 0 : v.owner_address) || '';
        // Variables address that named with many ways
        fallbacks.last_sold_date = (v === null || v === void 0 ? void 0 : v.last_sold_date) ? moment(v === null || v === void 0 ? void 0 : v.last_sold_date, models_3.dateTime.lastSoldDateDataFormat).format(models_3.dateTime.dateTimeUSFormat)
            : '';
        fallbacks.last_sold_price = noneAvailableValues.includes(v.last_sold_price)
            ? ''
            : this.checkValidVarFormat('last_sold_price', v === null || v === void 0 ? void 0 : v.last_sold_price)
                ? currency_1.formatCurrencySymbol((_b = Number((v === null || v === void 0 ? void 0 : v.last_sold_price) || null)) === null || _b === void 0 ? void 0 : _b.toString(), 'USD', true)
                : '';
        fallbacks.avm_value = this.checkValidVarFormat('avm_value', v === null || v === void 0 ? void 0 : v.avm_value)
            ? currency_1.formatCurrencySymbol((_c = Number((v === null || v === void 0 ? void 0 : v.avm_value) || null)) === null || _c === void 0 ? void 0 : _c.toString(), 'USD', true)
            : this.checkValidVarFormat('avm_value', v === null || v === void 0 ? void 0 : v.home_value)
                ? currency_1.formatCurrencySymbol((_d = Number((v === null || v === void 0 ? void 0 : v.home_value) || null)) === null || _d === void 0 ? void 0 : _d.toString(), 'USD', true)
                : '';
        fallbacks.assessed_or_active_value = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.price)
            ? ''
            : (this.checkValidVarFormat('assessed_or_active_value', v === null || v === void 0 ? void 0 : v.price)
                ? currency_1.formatCurrencySymbol((_e = Number((v === null || v === void 0 ? void 0 : v.price) || null)) === null || _e === void 0 ? void 0 : _e.toString(), 'USD', true)
                : '') || '';
        fallbacks.assessed_home_value = fallbacks.assessed_or_active_value || '';
        fallbacks.average_years_owned = noneAvailableValues.includes((_f = v === null || v === void 0 ? void 0 : v.mailing_list) === null || _f === void 0 ? void 0 : _f.avg_years_owned)
            ? ''
            : (_h = Number(((_g = v === null || v === void 0 ? void 0 : v.mailing_list) === null || _g === void 0 ? void 0 : _g.avg_years_owned) || null)) === null || _h === void 0 ? void 0 : _h.toString();
        fallbacks.square_feet = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.property_sqft)
            ? ''
            : this.checkValidVarFormat('property_sqft', v === null || v === void 0 ? void 0 : v.property_sqft)
                ? currency_1.formatCurrencySymbol((_j = Number((v === null || v === void 0 ? void 0 : v.property_sqft) || null)) === null || _j === void 0 ? void 0 : _j.toString(), '', true)
                : '';
        fallbacks.num_of_bathrooms = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.property_nbr_bathrooms)
            ? ''
            : ((_k = Number((v === null || v === void 0 ? void 0 : v.property_nbr_bathrooms) || null)) === null || _k === void 0 ? void 0 : _k.toString()) || '';
        fallbacks.num_of_bedrooms = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.property_nbr_bedrooms)
            ? ''
            : ((_l = Number((v === null || v === void 0 ? void 0 : v.property_nbr_bedrooms) || null)) === null || _l === void 0 ? void 0 : _l.toString()) || '';
        fallbacks.home_equity =
            ((v === null || v === void 0 ? void 0 : v.gross_equity) != null && ((_m = v === null || v === void 0 ? void 0 : v.gross_equity) === null || _m === void 0 ? void 0 : _m.toString()) ? `${number_1.formatCommas(v === null || v === void 0 ? void 0 : v.gross_equity)}%` : '') || '';
        fallbacks.home_equity_percentage = fallbacks.home_equity || '';
        fallbacks.homeowner_industry = (v === null || v === void 0 ? void 0 : v.industry) || '';
        fallbacks.turn_over_rate = noneAvailableValues.includes((_o = v === null || v === void 0 ? void 0 : v.mailing_list) === null || _o === void 0 ? void 0 : _o.turnover_rate)
            ? ''
            : ((_q = Number(((_p = v === null || v === void 0 ? void 0 : v.mailing_list) === null || _p === void 0 ? void 0 : _p.turnover_rate) || null)) === null || _q === void 0 ? void 0 : _q.toString()) || '';
        fallbacks.year_built = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.year_built)
            ? ''
            : ((_r = Number((v === null || v === void 0 ? void 0 : v.year_built) || null)) === null || _r === void 0 ? void 0 : _r.toString()) || '';
        fallbacks.remodeling_value = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.remodeling_value)
            ? ''
            : this.checkValidVarFormat('remodeling_value', v === null || v === void 0 ? void 0 : v.remodeling_value)
                ? currency_1.formatCurrencySymbol((_s = Number((v === null || v === void 0 ? void 0 : v.remodeling_value) || null)) === null || _s === void 0 ? void 0 : _s.toString(), 'USD', true)
                : '';
        fallbacks.remodeling_value_of_home = fallbacks.remodeling_value || '';
        const homeValue = (v === null || v === void 0 ? void 0 : v.avm_value) || (v === null || v === void 0 ? void 0 : v.home_value) || 0;
        const remodelingValue = v === null || v === void 0 ? void 0 : v.remodeling_value;
        fallbacks.remodel_upside_of_home =
            (this.checkValidVarFormat('avm_value', v === null || v === void 0 ? void 0 : v.avm_value) || this.checkValidVarFormat('avm_value', v === null || v === void 0 ? void 0 : v.home_value)) &&
                this.checkValidVarFormat('remodeling_value', v === null || v === void 0 ? void 0 : v.remodeling_value)
                ? currency_1.formatCurrencySymbol(Number(remodelingValue) - Number(homeValue), 'USD', true)
                : '';
        fallbacks.neighborhood = ((_t = v === null || v === void 0 ? void 0 : v.rea_neighborhood) === null || _t === void 0 ? void 0 : _t.name) || '';
        fallbacks.home_type = (v === null || v === void 0 ? void 0 : v.property_type) || '';
        // MLS fallbacks
        fallbacks.home_owner_company = '';
        fallbacks.street_name = fallbacks.street_name_only_in_mailling_list || '';
        fallbacks.street_number_and_name = fallbacks.street_number_and_name_only_in_mailling_list || '';
        fallbacks.equity_percent = fallbacks.home_equity || '';
        fallbacks.stories = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.property_stories)
            ? ''
            : ((_u = Number((v === null || v === void 0 ? void 0 : v.property_stories) || null)) === null || _u === void 0 ? void 0 : _u.toString()) || '';
        fallbacks.dom = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.dom) ? '' : ((_v = Number((v === null || v === void 0 ? void 0 : v.dom) || null)) === null || _v === void 0 ? void 0 : _v.toString()) || '';
        fallbacks.listed_price = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.list_price)
            ? ''
            : this.checkValidVarFormat('listed_price', v === null || v === void 0 ? void 0 : v.list_price)
                ? currency_1.formatCurrencySymbol((_w = Number((v === null || v === void 0 ? void 0 : v.list_price) || null)) === null || _w === void 0 ? void 0 : _w.toString(), 'USD', true)
                : '';
        fallbacks.years_owned = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.years_owned)
            ? ''
            : ((_x = Number((v === null || v === void 0 ? void 0 : v.years_owned) || null)) === null || _x === void 0 ? void 0 : _x.toString()) || '';
        const mlsDescriptionSentences = ((_y = v === null || v === void 0 ? void 0 : v.mls_description) === null || _y === void 0 ? void 0 : _y.split('.')) || [];
        fallbacks.first_two_lines_mls_description = mlsDescriptionSentences.length
            ? mlsDescriptionSentences.length > 2
                ? mlsDescriptionSentences.slice(0, 2).join('.')
                : v === null || v === void 0 ? void 0 : v.mls_description
            : '';
        fallbacks.listing_price_per_square_foot = noneAvailableValues.includes(v === null || v === void 0 ? void 0 : v.listing_price_per_square_foot)
            ? ''
            : this.checkValidVarFormat('listing_price_per_square_foot', v === null || v === void 0 ? void 0 : v.listing_price_per_square_foot)
                ? currency_1.formatCurrencySymbol((_z = Number((v === null || v === void 0 ? void 0 : v.listing_price_per_square_foot) || null)) === null || _z === void 0 ? void 0 : _z.toString(), 'USD', true)
                : '';
        // Handle Image
        fallbacks.image_1 = ((_0 = v === null || v === void 0 ? void 0 : v.mls_media) === null || _0 === void 0 ? void 0 : _0.length) ? (_1 = v === null || v === void 0 ? void 0 : v.mls_media[0]) === null || _1 === void 0 ? void 0 : _1.MediaURL : '';
        fallbacks.image_2 = ((_2 = v === null || v === void 0 ? void 0 : v.mls_media) === null || _2 === void 0 ? void 0 : _2.length) ? (_3 = v === null || v === void 0 ? void 0 : v.mls_media[1]) === null || _3 === void 0 ? void 0 : _3.MediaURL : '';
        fallbacks.image_3 = ((_4 = v === null || v === void 0 ? void 0 : v.mls_media) === null || _4 === void 0 ? void 0 : _4.length) ? (_5 = v === null || v === void 0 ? void 0 : v.mls_media[2]) === null || _5 === void 0 ? void 0 : _5.MediaURL : '';
        fallbacks.image_4 = ((_6 = v === null || v === void 0 ? void 0 : v.mls_media) === null || _6 === void 0 ? void 0 : _6.length) ? (_7 = v === null || v === void 0 ? void 0 : v.mls_media[3]) === null || _7 === void 0 ? void 0 : _7.MediaURL : '';
        fallbacks.image_5 = ((_8 = v === null || v === void 0 ? void 0 : v.mls_media) === null || _8 === void 0 ? void 0 : _8.length) ? (_9 = v === null || v === void 0 ? void 0 : v.mls_media[4]) === null || _9 === void 0 ? void 0 : _9.MediaURL : '';
        fallbacks.image_6 = ((_10 = v === null || v === void 0 ? void 0 : v.mls_media) === null || _10 === void 0 ? void 0 : _10.length) ? (_11 = v === null || v === void 0 ? void 0 : v.mls_media[5]) === null || _11 === void 0 ? void 0 : _11.MediaURL : '';
        fallbacks.street_view_image = (v === null || v === void 0 ? void 0 : v.street_view_image) || '';
        // Extra Data
        const extraDataKeys = Object.keys(v.extra_data || {});
        extraDataKeys.forEach((extraKey) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
            if (isNotGetExtraData) {
                if (extraKey === 'listing_image') {
                    fallbacks[extraKey] = [];
                }
                else {
                    fallbacks[extraKey] = '';
                }
                return;
            }
            if (extraKey === 'years_since_purchased') {
                const yearSincePurchased = (v === null || v === void 0 ? void 0 : v.last_sold_date) ? (Number(moment().format('YYYY')) -
                    Number(moment(v === null || v === void 0 ? void 0 : v.last_sold_date, models_3.dateTime.lastSoldDateDataFormat).format('YYYY'))).toString()
                    : '';
                fallbacks[extraKey] = ((_b = (_a = v === null || v === void 0 ? void 0 : v.extra_data) === null || _a === void 0 ? void 0 : _a.years_since_purchased) === null || _b === void 0 ? void 0 : _b.toString()) || yearSincePurchased;
            }
            else if (extraKey === 'listing_image') {
                const listingImageInGlobalFallback = ((_c = templateFallback === null || templateFallback === void 0 ? void 0 : templateFallback.default_fallbacks) === null || _c === void 0 ? void 0 : _c.listing_image) || [];
                const listingImageInExtraData = ((_d = v === null || v === void 0 ? void 0 : v.extra_data) === null || _d === void 0 ? void 0 : _d.listing_image) || [];
                const listingImages = [...listingImageInGlobalFallback, ...listingImageInExtraData];
                fallbacks[extraKey] = listingImages;
            }
            else if (extraKey === 'listing_image_1') {
                fallbacks[extraKey] =
                    (typeof ((_e = v === null || v === void 0 ? void 0 : v.extra_data) === null || _e === void 0 ? void 0 : _e.listing_image_1) === 'string'
                        ? (_f = v === null || v === void 0 ? void 0 : v.extra_data) === null || _f === void 0 ? void 0 : _f.listing_image_1 : (_h = (_g = v === null || v === void 0 ? void 0 : v.extra_data) === null || _g === void 0 ? void 0 : _g.listing_image_1) === null || _h === void 0 ? void 0 : _h.url) || '';
            }
            else if (extraKey === 'listing_image_2') {
                fallbacks[extraKey] =
                    (typeof ((_j = v === null || v === void 0 ? void 0 : v.extra_data) === null || _j === void 0 ? void 0 : _j.listing_image_2) === 'string'
                        ? (_k = v === null || v === void 0 ? void 0 : v.extra_data) === null || _k === void 0 ? void 0 : _k.listing_image_2 : (_m = (_l = v === null || v === void 0 ? void 0 : v.extra_data) === null || _l === void 0 ? void 0 : _l.listing_image_2) === null || _m === void 0 ? void 0 : _m.url) || '';
            }
            else if (extraKey === 'listing_image_3') {
                fallbacks[extraKey] =
                    (typeof ((_o = v === null || v === void 0 ? void 0 : v.extra_data) === null || _o === void 0 ? void 0 : _o.listing_image_3) === 'string'
                        ? (_p = v === null || v === void 0 ? void 0 : v.extra_data) === null || _p === void 0 ? void 0 : _p.listing_image_3 : (_r = (_q = v === null || v === void 0 ? void 0 : v.extra_data) === null || _q === void 0 ? void 0 : _q.listing_image_3) === null || _r === void 0 ? void 0 : _r.url) || '';
            }
            else if (extraKey === 'listing_image_4') {
                fallbacks[extraKey] =
                    (typeof ((_s = v === null || v === void 0 ? void 0 : v.extra_data) === null || _s === void 0 ? void 0 : _s.listing_image_4) === 'string'
                        ? (_t = v === null || v === void 0 ? void 0 : v.extra_data) === null || _t === void 0 ? void 0 : _t.listing_image_4 : (_v = (_u = v === null || v === void 0 ? void 0 : v.extra_data) === null || _u === void 0 ? void 0 : _u.listing_image_4) === null || _v === void 0 ? void 0 : _v.url) || '';
            }
            else if (extraKey === 'listing_image_5') {
                fallbacks[extraKey] =
                    (typeof ((_w = v === null || v === void 0 ? void 0 : v.extra_data) === null || _w === void 0 ? void 0 : _w.listing_image_5) === 'string'
                        ? (_x = v === null || v === void 0 ? void 0 : v.extra_data) === null || _x === void 0 ? void 0 : _x.listing_image_5 : (_z = (_y = v === null || v === void 0 ? void 0 : v.extra_data) === null || _y === void 0 ? void 0 : _y.listing_image_5) === null || _z === void 0 ? void 0 : _z.url) || '';
            }
            else {
                const extraDataMatchKey = (_0 = v === null || v === void 0 ? void 0 : v.extra_data) === null || _0 === void 0 ? void 0 : _0[extraKey];
                fallbacks[extraKey] = noneAvailableValues.includes(extraDataMatchKey) ? '' : extraDataMatchKey;
            }
        });
        // Extra Data
        return fallbacks;
    }
    listAddressFromMailingIds(page = 1, limit = 100, mailing_ids, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { page, limit, mailing_ids, agent_id };
            const endpoint = `/postalytics/addresses-list/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.listAddressFromMailingIds,
                });
            return response;
        });
    }
    getNewestMlsMailings(mailingList_id, is_my_listing) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = { is_my_listing };
            const endpoint = `/mailing-list/${mailingList_id}/newest-mls-status/`;
            const cacheKey = this.caching.generateCacheKey(endpoint, params);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return cachedData.data;
            }
            const response = yield this.client.get(endpoint, params);
            if (cacheKey)
                this.caching.setCache(cacheKey, {
                    data: response,
                    timestamp: Date.now(),
                    staleTime: this.staleTimes.getNewestMlsMailings,
                });
            return response;
        });
    }
    setGlobalFallbacks(data, agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const getEndpoint = `/postalytics/get-global-fallbacks/`;
            this.caching.deleteCacheByEndpoint(getEndpoint);
            data.default_fallbacks = this.formatGlobalVariable(data.default_fallbacks);
            return this.client.post(`/postalytics/set-global-fallbacks/`, data, { agent_id });
        });
    }
    getGlobalFallbacks(variables = [], agent_id) {
        return __awaiter(this, void 0, void 0, function* () {
            const endpoint = `/postalytics/get-global-fallbacks/`;
            const cacheKey = this.caching.generateCacheKey(`${endpoint}${agent_id || null}`, variables);
            if (cacheKey) {
                const cachedData = this.caching.getCache(cacheKey);
                if (cachedData)
                    return this.formatGlobalVariable(cachedData.data);
            }
            const data = { variables: variables };
            try {
                const global = yield this.client.post(endpoint, data, {
                    agent_id,
                });
                const response = this.formatGlobalVariable(global);
                if (cacheKey)
                    this.caching.setCache(cacheKey, {
                        data: response,
                        timestamp: Date.now(),
                        staleTime: this.staleTimes.getGlobalFallbacks,
                    });
                return response;
            }
            catch (error) {
                return {};
            }
        });
    }
    convertUserPostgridMLSVariablesWithSelectedAddress(templateFallback, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const newTemplateFallback = object_1.cloneDeep(templateFallback);
            if (newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mls_data) {
                const regex = this.getPrefixRegex();
                const mailingIds = [...uniqBy(Object.values((newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mls_data) || {}), (v) => v)];
                if (!(mailingIds === null || mailingIds === void 0 ? void 0 : mailingIds.length))
                    return newTemplateFallback;
                const result = yield this.listAddressFromMailingIds(1, 1000, mailingIds.join(','));
                const mailings = result.results;
                const keys = Object.keys((newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mls_data) || {});
                keys.forEach((mlsAddressVar) => {
                    var _a, _b, _c, _d, _e;
                    const prefix = (_b = (_a = `${mlsAddressVar}`) === null || _a === void 0 ? void 0 : _a.split('_')) === null || _b === void 0 ? void 0 : _b[0];
                    let suffixVar = mlsAddressVar;
                    if (prefix) {
                        suffixVar = (_c = mlsAddressVar === null || mlsAddressVar === void 0 ? void 0 : mlsAddressVar.split(`${prefix}_`)) === null || _c === void 0 ? void 0 : _c[1];
                    }
                    const mailingId = (_d = newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mls_data) === null || _d === void 0 ? void 0 : _d[mlsAddressVar];
                    const mailingData = mailings === null || mailings === void 0 ? void 0 : mailings.find((v) => mailingId && v.id === mailingId);
                    if (!mailingData)
                        return;
                    // Put is my listing status for mls address var when converting
                    const hasStatus = (_e = this.statuses) === null || _e === void 0 ? void 0 : _e.some((status) => suffixVar && status && (suffixVar === null || suffixVar === void 0 ? void 0 : suffixVar.startsWith(status)));
                    const hasNml = suffixVar === null || suffixVar === void 0 ? void 0 : suffixVar.includes('nml');
                    const isMyListing = hasStatus ? (hasNml ? false : true) : mailingData === null || mailingData === void 0 ? void 0 : mailingData.is_my_listing;
                    mailingData.is_my_listing = isMyListing;
                    const realDataFallbacks = this.handleFallbackRules(mailingData, {
                        templateFallback: newTemplateFallback,
                        isSendOwnerAddress: mailingData.is_send_owner_address,
                        type,
                    });
                    const realKey = `${suffixVar}`.replace(regex, '');
                    newTemplateFallback.default_fallbacks = Object.assign({}, newTemplateFallback.default_fallbacks);
                    const mlsRealData = realDataFallbacks[realKey];
                    // const mlsGlobalData = newTemplateFallback.default_fallbacks[mlsAddressVar];
                    newTemplateFallback.default_fallbacks[mlsAddressVar] = mlsRealData || '';
                });
            }
            return newTemplateFallback;
        });
    }
    convertUserPostgridMLSVariablesWithLatestHomeOfMailingList(templateFallback, type) {
        return __awaiter(this, void 0, void 0, function* () {
            const newTemplateFallback = object_1.cloneDeep(templateFallback);
            if (newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data) {
                const regex = this.getPrefixRegex();
                let mlMailingListIds = [];
                let nmlMailingListIds = [];
                let pcMailingListIds = [];
                const allKeys = Object.keys(newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data);
                allKeys.forEach((key) => {
                    var _a, _b, _c;
                    const isPastClientKey = this.prefixKeys.some((v) => key.includes(v)) && key.includes('pc_');
                    const isMLKey = this.prefixKeys.some((v) => key.includes(v)) && !key.includes('pc_') && !key.includes('nml_');
                    const isNMLKey = this.prefixKeys.some((v) => key.includes(v)) && !key.includes('pc_') && key.includes('nml_');
                    if (isPastClientKey) {
                        pcMailingListIds.push((_a = newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data) === null || _a === void 0 ? void 0 : _a[key]);
                        pcMailingListIds = [
                            ...uniqBy(pcMailingListIds.filter((e) => !!e), (v) => v),
                        ];
                    }
                    else if (isMLKey) {
                        mlMailingListIds.push((_b = newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data) === null || _b === void 0 ? void 0 : _b[key]);
                        mlMailingListIds = [
                            ...uniqBy(mlMailingListIds.filter((e) => !!e), (v) => v),
                        ];
                    }
                    else if (isNMLKey) {
                        nmlMailingListIds.push((_c = newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data) === null || _c === void 0 ? void 0 : _c[key]);
                        nmlMailingListIds = [
                            ...uniqBy(nmlMailingListIds.filter((e) => !!e), (v) => v),
                        ];
                    }
                });
                if (!(mlMailingListIds === null || mlMailingListIds === void 0 ? void 0 : mlMailingListIds.length) && !(nmlMailingListIds === null || nmlMailingListIds === void 0 ? void 0 : nmlMailingListIds.length) && !(pcMailingListIds === null || pcMailingListIds === void 0 ? void 0 : pcMailingListIds.length))
                    return newTemplateFallback;
                const mlsStatusAbbreviationToFull = Object.keys(models_2.MLS_STATUS_PREFIX)
                    .filter((v) => v !== null)
                    .map((key) => {
                    return {
                        abbr: key,
                        status: models_2.MLS_STATUS_PREFIX[key],
                    };
                });
                const handleAppendDataForVars = ({ pcMailingResult, notMyListingResult, myListingResult }) => {
                    allKeys.forEach((mlsAddressVar) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h;
                        if (!((_a = newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data) === null || _a === void 0 ? void 0 : _a[mlsAddressVar]))
                            return;
                        let mailingListNameInVar = '';
                        const prefixVar = this.prefixKeys.find((prefix) => mlsAddressVar.includes(prefix));
                        if (prefixVar) {
                            mailingListNameInVar = mlsAddressVar.split(prefixVar)[0].replace('_', '');
                        }
                        const isNewMyListingVarFromAdmin = mailingListNameInVar === models_2.NEW_MY_LISTING_PREFIX_FROM_ADMIN;
                        if (isNewMyListingVarFromAdmin) {
                            mailingListNameInVar = '';
                        }
                        const isPastClient = this.prefixKeys.some((v) => mlsAddressVar.includes(v)) && mlsAddressVar.includes('pc_');
                        const isNotMyListing = this.prefixKeys.some((v) => mlsAddressVar.includes(v)) &&
                            !mlsAddressVar.includes('pc_') &&
                            mlsAddressVar.includes('nml_');
                        let mailingData = null;
                        if (isPastClient && pcMailingResult) {
                            const pcMailingResultArray = Object.values(pcMailingResult || {});
                            if (!pcMailingResultArray.length)
                                return;
                            const pcType = (_c = (((_b = pcMailingResultArray[0].mailing_list) === null || _b === void 0 ? void 0 : _b.past_client_type) || '')) === null || _c === void 0 ? void 0 : _c.toLowerCase();
                            const propertyWithStatus = mlsAddressVar.split(mailingListNameInVar ? `${mailingListNameInVar}_pc_${pcType}_` : `pc_${pcType}_`)[1];
                            const foundMlsStatusAbbreviation = mlsStatusAbbreviationToFull.find((e) => propertyWithStatus && e.status && propertyWithStatus.startsWith(e.status));
                            if ((foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr) && pcMailingResult) {
                                mailingData = pcMailingResult[foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr];
                            }
                            else {
                                this.log('not_found_pc__', pcMailingResult);
                            }
                        }
                        else if (isNotMyListing && notMyListingResult) {
                            const propertyWithStatus = mlsAddressVar.split(mailingListNameInVar ? `${mailingListNameInVar}_nml_` : 'nml_')[1];
                            const foundMlsStatusAbbreviation = mlsStatusAbbreviationToFull.find((e) => propertyWithStatus && e.status && propertyWithStatus.startsWith(e.status));
                            if ((foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr) && notMyListingResult) {
                                mailingData = notMyListingResult[foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr];
                            }
                            else {
                                this.log('not_found_nml__', foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr, notMyListingResult);
                            }
                        }
                        else if (!isPastClient && !isNotMyListing && myListingResult) {
                            let propertyWithStatus = '';
                            if (isNewMyListingVarFromAdmin) {
                                propertyWithStatus = mlsAddressVar.split(`${models_2.NEW_MY_LISTING_PREFIX_FROM_ADMIN}_`)[1];
                            }
                            else {
                                propertyWithStatus = mailingListNameInVar
                                    ? mlsAddressVar.split(`${mailingListNameInVar}_`)[1]
                                    : mlsAddressVar;
                            }
                            const foundMlsStatusAbbreviation = mlsStatusAbbreviationToFull.find((e) => propertyWithStatus && e.status && propertyWithStatus.startsWith(e.status));
                            if ((foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr) && myListingResult) {
                                mailingData = myListingResult[foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr];
                            }
                            else {
                                this.log('not_found_ml__', foundMlsStatusAbbreviation === null || foundMlsStatusAbbreviation === void 0 ? void 0 : foundMlsStatusAbbreviation.abbr, myListingResult);
                            }
                        }
                        if (!mailingData)
                            return;
                        const mailingListVarId = (_d = newTemplateFallback === null || newTemplateFallback === void 0 ? void 0 : newTemplateFallback.mailing_list_data) === null || _d === void 0 ? void 0 : _d[mlsAddressVar];
                        if (mailingListVarId && ((_e = mailingData === null || mailingData === void 0 ? void 0 : mailingData.mailing_list) === null || _e === void 0 ? void 0 : _e.id) !== mailingListVarId) {
                            return;
                        }
                        this.log('mlsAddressVar====', mlsAddressVar, mailingData);
                        // Put is my listing status for mls address var when converting
                        mailingData.is_my_listing = !isNotMyListing;
                        const realDataFallbacks = this.handleFallbackRules(mailingData, {
                            templateFallback: newTemplateFallback,
                            isSendOwnerAddress: mailingData.is_send_owner_address,
                            type,
                        });
                        let mlsAddressVarNoMLName = '';
                        if (isNewMyListingVarFromAdmin) {
                            mlsAddressVarNoMLName = mlsAddressVar.replace(`${models_2.NEW_MY_LISTING_PREFIX_FROM_ADMIN}_`, '');
                        }
                        else {
                            mlsAddressVarNoMLName = mailingListNameInVar
                                ? mlsAddressVar.replace(`${mailingListNameInVar}_`, '')
                                : mlsAddressVar;
                        }
                        const realKey = `${mlsAddressVarNoMLName}`.replace(regex, '');
                        newTemplateFallback.default_fallbacks = Object.assign({}, newTemplateFallback.default_fallbacks);
                        const pcType = ((_f = mailingData.mailing_list) === null || _f === void 0 ? void 0 : _f.past_client_type) ? (_g = mailingData.mailing_list) === null || _g === void 0 ? void 0 : _g.past_client_type.toLowerCase() : '';
                        const mailingListNameInMailing = (_h = mailingData === null || mailingData === void 0 ? void 0 : mailingData.mailing_list) === null || _h === void 0 ? void 0 : _h.name.replace(/[^a-zA-Z0-9]/g, '');
                        if (pcType) {
                            if (mailingListNameInVar === '') {
                                // Case on Admin Django - Not Have Mailing List name on variables
                                // Past Client
                                const mlsRealData = realDataFallbacks[realKey] || '';
                                if (mlsAddressVar === null || mlsAddressVar === void 0 ? void 0 : mlsAddressVar.startsWith(`pc_${pcType}`)) {
                                    newTemplateFallback.default_fallbacks[mlsAddressVar] = mlsRealData || '';
                                }
                                else {
                                    newTemplateFallback.default_fallbacks[mlsAddressVar] = '';
                                }
                            }
                            else {
                                // Past Client
                                const pcRealData = mailingListNameInMailing === mailingListNameInVar ? realDataFallbacks[realKey] || '' : '';
                                // const pcGlobalData = newTemplateFallback.default_fallbacks[mlsAddressVar];
                                if (mlsAddressVarNoMLName === null || mlsAddressVarNoMLName === void 0 ? void 0 : mlsAddressVarNoMLName.startsWith(`pc_${pcType}`)) {
                                    newTemplateFallback.default_fallbacks[mlsAddressVar] = pcRealData || '';
                                }
                                else {
                                    newTemplateFallback.default_fallbacks[mlsAddressVar] = '';
                                }
                            }
                        }
                        else {
                            if (mailingListNameInVar === '') {
                                // Case on Admin Django - Not Have Mailing List name on variables
                                // My Listing - Not My Listing
                                const mlsRealData = realDataFallbacks[realKey] || '';
                                newTemplateFallback.default_fallbacks[mlsAddressVar] = mlsRealData || '';
                            }
                            else {
                                // My Listing - Not My Listing
                                const mlsRealData = mailingListNameInMailing === mailingListNameInVar ? realDataFallbacks[realKey] || '' : '';
                                // const mlsGlobalData = newTemplateFallback.default_fallbacks[mlsAddressVar];
                                newTemplateFallback.default_fallbacks[mlsAddressVar] = mlsRealData || '';
                            }
                        }
                    });
                };
                // My Listing
                for (const mlsMailingListId of mlMailingListIds) {
                    const myListingResult = mlsMailingListId ? yield this.getNewestMlsMailings(mlsMailingListId, true) : null;
                    handleAppendDataForVars({
                        myListingResult,
                    });
                }
                // Not My Listing
                for (const mlsMailingListId of nmlMailingListIds) {
                    const notMyListingResult = mlsMailingListId ? yield this.getNewestMlsMailings(mlsMailingListId, false) : null;
                    handleAppendDataForVars({
                        notMyListingResult,
                    });
                }
                // Past Client
                for (const pcMailingListId of pcMailingListIds) {
                    const pcMailingResult = pcMailingListId ? yield this.getNewestMlsMailings(pcMailingListId) : null;
                    handleAppendDataForVars({
                        pcMailingResult,
                    });
                }
            }
            return newTemplateFallback;
        });
    }
}
exports.VariableTool = VariableTool;
