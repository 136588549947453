import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { PostGridTemplate, TemplateViewSide } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import { ModalProps, message } from 'antd';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import loaderGif from '@/assets/gif/loading.gif';
import { PreviewTemplateAction, TemplateITemEnum } from '@/utils/enum';

import { FallbackTypeEnum, handleExcludedPostgridHiddenVars } from '@/components/custom-modals/modal-fallbacks/types';
import RenderHTML from '@/components/dashboard/campaign/ScrollTemplate/RenderHTML';
import useDisableBySubscription from '@/hooks/useDisableBySubscription';
import AppAction from '@/reducers/app/action';
import { RootState } from '@/reducers/model';
import { LETTER_TEMPLATE_SIZE, imageRatio } from '@/utils/constant';
import RoutePaths from '@/utils/routes';
import { calcResolutionSize, formatFallback, getListVarsOnFallbackForm } from '@/utils/utils';

import useHandlePreviewSingleTemplate from './hooks/useHandlePreviewSingleTemplate';
import useFetchDefaultTemplateFallback from './modal-preview-template-with-mailing-list/hooks/useFetchDefaultTemplateFallback';
import { cloneDeep } from 'lodash';
import { fallbackPrefixes } from '../modal-fallbacks/hooks/useFallbackPostgridFormStore';
import { AlertTextError } from '@/components/alert/SweetAlert';
import { HandwrittenTemplateCustom } from '@/components/dashboard/campaign/ScrollTemplate/hooks/useScrollTemplate';
import { CardTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import SetFallbackPostgridForm from '../modal-fallbacks/SetFallbackPostgridForm';
import BaseModal from '@/components/Modal/index';
import AppButtonV2 from '@/components/AppButtonV2';
import { color } from '@/styles/theme';
import { MdOutlineArrowDropDown } from 'react-icons/md';

interface PreviewTemplateModalProps extends ModalProps {
  htmlString: string;
  video?: string;
  visible: boolean;
  className?: string;
  onCancel: () => void;
  data: any;
  onFooterButtonClick?: (value: any, previewTemplateType: PreviewTemplateAction) => void;
  isPreviewRawTemplate?: boolean;
}

const ModalPreviewTemplate: FC<PreviewTemplateModalProps> = ({
  visible,
  data,
  onCancel,
  className,
  onFooterButtonClick,
  isPreviewRawTemplate = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const listAllVariable = useSelector((state: RootState) => state.campaign.listPostgridSessionVar);
  const { templateFallback, updateFallbackOfTemplate } = useFetchDefaultTemplateFallback(
    data?.id,
    false,
    data?.front_template + data?.back_template,
  );

  const type = data?.type || data?.digital_ad_type || TemplateITemEnum.POSTCARD;

  const isHandwritten = type === TemplateITemEnum.HANDWRITTEN;

  const [previewThumbnailSide, setPreviewThumbnailSide] = useState<TemplateViewSide>(
    data?.side?.toString() || TemplateViewSide.FRONT,
  );
  const [showFallbackForm, setShowFallbackForm] = useState<boolean>(false);
  const isDrip = [RoutePaths.DRIP_CAMPAIGNS, RoutePaths.DRIP_CAMPAIGNS_CREATE].some((v) =>
    window.location.pathname.includes(v),
  );
  const [fallbackValue, setFallbackValue] = useState<any>({});
  const isHideFooterBtn = data?.is_premium && !data?.is_purchased;

  const { isDisableBySubscription } = useDisableBySubscription();

  const { renderPreviewData } = useHandlePreviewSingleTemplate();

  const handleActiveSubscription = () => {
    dispatch({
      type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
      payload: true,
    });
    onCancel();
  };

  const onChangeFallbacks = (data: any) => {
    if (data && Object.keys(data)?.length) {
      setFallbackValue(data);
    }
  };

  useEffect(() => {
    if (visible) {
      message.destroy();
    }
    setPreviewThumbnailSide(data?.side || TemplateViewSide.FRONT);
    setShowFallbackForm(false);
  }, [visible]);

  useEffect(() => {
    if (templateFallback?.default_fallbacks) {
      setFallbackValue(templateFallback?.default_fallbacks || {});
    }
  }, [templateFallback, visible]);

  const htmlString = useMemo(() => {
    return renderPreviewData(data.type || data.digital_ad_type, data, previewThumbnailSide, {});
  }, [previewThumbnailSide, data]);

  const isDisableGlobalFallbackButton = useMemo(() => {
    const htmlString = isHandwritten
      ? `${(data as HandwrittenTemplateCustom).message_template || ''}`
      : `${((data as PostGridTemplate)?.front_template || '') + ((data as PostGridTemplate)?.back_template || '')}`;
    return !getListVarsOnFallbackForm(FallbackTypeEnum.POSTGRID, htmlString, listAllVariable)?.length;
  }, [isHandwritten, data, listAllVariable]);

  const templateHtmlString = useMemo(
    () =>
      isHandwritten
        ? (data as HandwrittenTemplateCustom).message_template || ''
        : ((data as PostGridTemplate)?.front_template || '') + ((data as PostGridTemplate)?.back_template || ''),
    [isHandwritten, data],
  );

  return (
    <PreviewModalBaseStyle
      visible={visible}
      {...rest}
      footer={false}
      destroyOnClose={true}
      centered
      closable={false}
      onCancel={onCancel}
      getContainer={false}
      className={className}
    >
      <PreviewModalTemplateContentStyled type={type} size={calcResolutionSize(data?.postcard_size, type)}>
        <p className="template-type" title={type === TemplateITemEnum.EMAIL ? data?.name : null}>
          {type === TemplateITemEnum.POSTCARD ? (
            data?.postcard_size ? (
              `POSTCARD (${data?.postcard_size})`
            ) : (
              'POSTCARD'
            )
          ) : type === TemplateITemEnum.LETTER ? (
            `LETTER ${LETTER_TEMPLATE_SIZE}`
          ) : type === TemplateITemEnum.EMAIL ? (
            data?.name
          ) : isHandwritten ? (
            <>
              {' '}
              <span>HANDWRITTEN</span>
              <br />
              <span>{data.name}</span>
            </>
          ) : null}
        </p>
        <RenderHTML
          className="preview-content"
          id={'preview'}
          htmlString={htmlString}
          type={type}
          isPreviewRawTemplate={isPreviewRawTemplate}
        />
        <div className="footer-btn">
          {![TemplateITemEnum.DIGITAL_AD_VIDEO, TemplateITemEnum.DIGITAL_AD_IMAGE].includes(type) ? (
            <div className="preview-btn">
              {![TemplateITemEnum.LETTER, TemplateITemEnum.EMAIL, TemplateITemEnum.HANDWRITTEN].includes(type) && (
                <div className="side-btn">
                  <AppButtonV2
                    size="small"
                    uppercase={false}
                    width={60}
                    color={previewThumbnailSide === TemplateViewSide.FRONT ? 'Pin' : 'Outline'}
                    outlineColor={color.PINK}
                    onClick={() => setPreviewThumbnailSide(TemplateViewSide.FRONT)}
                  >
                    Front
                  </AppButtonV2>

                  <AppButtonV2
                    size="small"
                    uppercase={false}
                    width={60}
                    color={previewThumbnailSide === TemplateViewSide.BACK ? 'Pin' : 'Outline'}
                    outlineColor={color.PINK}
                    onClick={() => setPreviewThumbnailSide(TemplateViewSide.BACK)}
                  >
                    Back
                  </AppButtonV2>
                </div>
              )}
              {isHandwritten && (
                <>
                  <div className="side-btn">
                    <AppButtonV2
                      size="small"
                      uppercase={false}
                      width={60}
                      color={previewThumbnailSide === TemplateViewSide.FRONT ? 'Pin' : 'Outline'}
                      outlineColor={color.PINK}
                      onClick={() => setPreviewThumbnailSide(TemplateViewSide.FRONT)}
                    >
                      Front
                    </AppButtonV2>
                    {data?.card_type === CardTypeEnum.Fold && (
                      <AppButtonV2
                        size="small"
                        uppercase={false}
                        width={60}
                        color={previewThumbnailSide === TemplateViewSide.INSIDE ? 'Pin' : 'Outline'}
                        outlineColor={color.PINK}
                        onClick={() => setPreviewThumbnailSide(TemplateViewSide.INSIDE)}
                      >
                        Inside
                      </AppButtonV2>
                    )}

                    <AppButtonV2
                      onClick={() => setPreviewThumbnailSide(TemplateViewSide.BACK)}
                      size="small"
                      uppercase={false}
                      width={60}
                      color={previewThumbnailSide === TemplateViewSide.BACK ? 'Pin' : 'Outline'}
                      outlineColor={color.PINK}
                    >
                      Back
                    </AppButtonV2>
                  </div>
                  <br />
                </>
              )}

              {[TemplateITemEnum.EMAIL].includes(type) && (
                <>
                  {data?.content ? (
                    <div className="side-btn">
                      <AppButtonV2
                        size="small"
                        uppercase={false}
                        width={180}
                        onClick={() =>
                          onFooterButtonClick?.(data, PreviewTemplateAction.CREATE_OWN_VERSION_EMAIL_TEMPLATE)
                        }
                      >
                        Create my own Version
                      </AppButtonV2>
                    </div>
                  ) : (
                    <div className="side-btn">
                      <AppButtonV2
                        width={120}
                        size="small"
                        uppercase={false}
                        onClick={() => onFooterButtonClick?.(data, PreviewTemplateAction.EDIT_EMAIL_TEMPLATE)}
                      >
                        Edit Template
                      </AppButtonV2>

                      <AppButtonV2
                        width={140}
                        size="small"
                        uppercase={false}
                        onClick={() => onFooterButtonClick?.(data, PreviewTemplateAction.DELETE_EMAIL_TEMPLATE)}
                      >
                        Delete Template
                      </AppButtonV2>
                    </div>
                  )}
                </>
              )}
              {!isHideFooterBtn && type !== TemplateITemEnum.EMAIL ? (
                <>
                  <AppButtonV2
                    size="small"
                    uppercase={false}
                    width={180}
                    onClick={() => {
                      if (isDisableBySubscription) {
                        handleActiveSubscription();
                        return;
                      }

                      onFooterButtonClick && onFooterButtonClick(data, PreviewTemplateAction.CREATE_FROM_TEMPLATE);
                    }}
                  >
                    Create from Template
                  </AppButtonV2>
                  {data?.user_id && (
                    <>
                      <AppButtonV2
                        width={120}
                        size="small"
                        uppercase={false}
                        onClick={() => {
                          if (isDisableBySubscription) {
                            handleActiveSubscription();
                            return;
                          }

                          onFooterButtonClick &&
                            onFooterButtonClick(data, PreviewTemplateAction.EDIT_POSTGRID_TEMPLATE);
                        }}
                      >
                        Edit Template
                      </AppButtonV2>
                      <AppButtonV2
                        width={140}
                        size="small"
                        uppercase={false}
                        onClick={() => {
                          if (isDisableBySubscription) {
                            handleActiveSubscription();
                            return;
                          }
                          onFooterButtonClick && onFooterButtonClick(data, PreviewTemplateAction.DELETE_TEMPLATE);
                        }}
                      >
                        Delete Template
                      </AppButtonV2>
                    </>
                  )}
                </>
              ) : null}
            </div>
          ) : data.qr_code && !isDrip ? (
            <AppButtonV2
              width={140}
              size="small"
              uppercase={false}
              onClick={() => {
                onFooterButtonClick && onFooterButtonClick(data, PreviewTemplateAction.UPDATE_QR_CODE);
              }}
            >
              Update QR Code
            </AppButtonV2>
          ) : (
            <div></div>
          )}

          <div className="fallback">
            {![TemplateITemEnum.DIGITAL_AD_VIDEO, TemplateITemEnum.DIGITAL_AD_IMAGE].includes(type) ? (
              !isHideFooterBtn && type !== TemplateITemEnum.EMAIL ? (
                <AppButtonV2
                  width={150}
                  size="small"
                  uppercase={false}
                  onClick={() => {
                    if (isDisableBySubscription) {
                      handleActiveSubscription();
                      return;
                    }
                    setShowFallbackForm((prev) => !prev);
                    onFooterButtonClick && onFooterButtonClick(true, PreviewTemplateAction.FALLBACK);
                  }}
                  style={{ marginLeft: 10 }}
                  disabled={isDisableGlobalFallbackButton}
                >
                  Global Fallback
                  <MdOutlineArrowDropDown fontSize={24} />
                </AppButtonV2>
              ) : null
            ) : (
              !isDrip && (
                <AppButtonV2
                  width={150}
                  size="small"
                  uppercase={false}
                  onClick={() => {
                    onFooterButtonClick && onFooterButtonClick(data, PreviewTemplateAction.USE_TEMPLATE_FOR_NEW_VIDEO);
                  }}
                >
                  Create my version
                </AppButtonV2>
              )
            )}
          </div>
        </div>
        {showFallbackForm && !isDisableBySubscription && (
          <>
            <SetFallbackPostgridForm
              uniqueFallbackFormId={`${fallbackPrefixes.PREVIEW_TEMPLATE}${data.id}`}
              onChange={onChangeFallbacks}
              className="postgrid-fallback"
              initialData={fallbackValue}
              isDripCampaign={false}
              isSms={false}
              htmlString={templateHtmlString}
              templateId={templateFallback?.template}
            />
            <div className="btn-group">
              <AppButtonV2
                size="large"
                width="240px"
                color="Pin"
                onClick={async () => {
                  const excludedPostgridHiddenVars = handleExcludedPostgridHiddenVars(fallbackValue);
                  const fallbackValueOnUI = formatFallback(cloneDeep(excludedPostgridHiddenVars), templateHtmlString);
                  try {
                    const saveFallback = await updateFallbackOfTemplate(data.id, fallbackValueOnUI);
                    onFooterButtonClick?.(
                      {
                        template: data.id,
                        default_fallbacks: saveFallback,
                        mls_data: data?.user_template_fallback?.mls_data || {},
                        mailing_list_data: data?.user_template_fallback?.mailing_list_data || {},
                      },
                      PreviewTemplateAction.SAVE_CUSTOM_FALLBACK_POSTGRID_TEMPLATE,
                    );
                  } catch (error) {
                    AlertTextError('Action Failed', (error as Error).message);
                  }
                }}
              >
                Save
              </AppButtonV2>
              <AppButtonV2
                size="large"
                width="240px"
                color="WhiteTransparent"
                onClick={() => {
                  setShowFallbackForm(false);
                  setFallbackValue(templateFallback?.default_fallbacks || {});
                }}
              >
                Cancel
              </AppButtonV2>
            </div>
          </>
        )}
      </PreviewModalTemplateContentStyled>
    </PreviewModalBaseStyle>
  );
};

export default ModalPreviewTemplate;

export const PreviewModalBaseStyle = styled(BaseModal)`
  min-width: 600px !important;
  width: fit-content !important;
  max-width: 70% !important;
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    .ant-modal-close-x {
      width: 44px;
      height: 44px;
      line-height: 44px;
    }
    .ant-modal-body,
    .preview-modal-wrapper {
      position: relative;
      padding: 40px 33px 80px 33px;
      border-radius: 12px;

      display: flex;
      flex-direction: column;
      gap: 50px;
      min-height: 510px;
      word-wrap: unset;
    }

    .ant-modal-body .preview-modal-wrapper {
      padding: 0;
    }
  }
  .error {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    max-width: 390px;
    margin: auto;
  }
  .ant-typography {
    margin-bottom: 0 !important;
  }
  .ant-btn {
    height: 52px;
    font-weight: 700 !important;
  }
  .hidden {
    display: none;
  }
`;

interface PreviewModalTemplateContentStyledProps {
  type?: TemplateITemEnum;
  size?: { width: number; height: number };
  isBrokerPreview?: boolean;
}

export const PreviewModalTemplateContentStyled = styled.div<PreviewModalTemplateContentStyledProps>`
  background: #2e0249;
  padding: 20px 33px 22px 33px;
  border-radius: 12px;
  width: fit-content;
  min-height: 500px;
  /* min-width: 892px; */
  &[type=${TemplateITemEnum.DIGITAL_AD_VIDEO}],
  &[type=${TemplateITemEnum.DIGITAL_AD_IMAGE}] {
    width: ${(props) => (props.isBrokerPreview ? '100%' : '768px')};
  }
  &[type=${TemplateITemEnum.LETTER}] {
    .postgrid-scale-style {
      min-height: 700px;
      width: ${({ size }) => (size?.width ? size.width + 'px!important' : 'auto')};
      height: ${({ size }) => (size?.height ? size.height + 'px!important' : 'auto')};
    }
  }
  &[type=${TemplateITemEnum.POSTCARD}] {
    .postgrid-scale-style {
      min-height: 408px;
      width: ${({ size }) => (size?.width ? size.width + 'px!important' : 'auto')};
      height: ${({ size }) => (size?.height ? size.height + 'px!important' : 'auto')};
    }
  }
  &[type=${TemplateITemEnum.HANDWRITTEN}] {
    min-width: 1000px;
    .postgrid-scale-style {
      min-height: 408px;
      width: ${({ size }) => (size?.width ? size.width + 'px!important' : 'auto')};
      height: ${({ size }) => (size?.height ? size.height + 'px!important' : 'auto')};
    }
  }
  &[type=${TemplateITemEnum.EMAIL}] {
    .postgrid-scale-style {
      min-height: 600px;
      width: ${({ size }) => (size?.width ? 800 + 'px!important' : 'auto')};
      max-width: 800px;
      height: ${({ size }) => (size?.height ? 800 + 'px!important' : 'auto')};
    }
    .template-type {
      max-width: 800px;
    }
  }
  .template-type {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 10px;
    color: #fff !important;

    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .address {
    color: #fff;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
  }
  .preview-content {
    margin-bottom: 20px;
    /* border: 3px solid #8c8c8c; */
    min-height: ${(props: { type?: TemplateITemEnum }) =>
      props?.type === TemplateITemEnum.LETTER ? '700px' : '420px'};

    min-width: ${(props) => (props.type === TemplateITemEnum.HANDWRITTEN ? '768px' : '600px')};
    max-width: ${(props) => (props.type === TemplateITemEnum.HANDWRITTEN ? '1024px' : 'auto')};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${`transparent url(${loaderGif}) center center no-repeat`};
    picture {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-top: ${imageRatio['16:9']}%;
      img {
        position: absolute;
        top: 0;
        height: 100%;
        width: auto;
      }
    }

    .postgrid-scale-style {
      max-height: 100%;
      position: unset;
      overflow: auto;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      background-color: #fff;
      width: ${(props) => (props.type === TemplateITemEnum.HANDWRITTEN ? '768px' : '100%')};
      height: ${(props) => (props.type === TemplateITemEnum.HANDWRITTEN ? '590px' : '100%')};
      max-height: ${(props) => (props.type === TemplateITemEnum.HANDWRITTEN ? '768px' : '710px')};
      overflow: auto;
      ${(props) =>
        props.type === TemplateITemEnum.EMAIL
          ? css`
              .postgrid-fake-body {
                height: auto !important;
                padding: 12px;
                .page {
                  ol {
                    padding-left: 30px;
                  }
                }
              }
            `
          : props.type === TemplateITemEnum.HANDWRITTEN
          ? css`
              .page {
                overflow-y: auto;
              }
            `
          : ''}
    }
  }
  .footer-btn {
    display: flex;
    justify-content: space-between;
    .ant-btn {
      height: 30px;
      color: #fff;
      font-weight: 700;
      border-color: #ff00dd;
      background-color: #ff00dd;
      &:disabled {
        opacity: 0.5;
      }
    }
    .fallback-btn-group {
      display: flex;
      gap: 16px;
    }
    .fallback {
      .fallback-btn {
        width: 115px;
        &.hide-arrow {
          .anticon {
            display: none;
          }
        }
      }
      .global-fallback {
        width: 160px;
      }
    }
    .save-fallback-to-mailing {
      .ant-btn {
        width: 115px;
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: center;

        &.disable-save {
          cursor: not-allowed;
          user-select: none;
        }
      }
    }
    .preview-btn {
      display: flex;
      gap: 10px;
      .ant-btn {
        background-color: transparent;
      }
      .side-btn {
        display: flex;
        gap: 5px;
      }
    }
    .active,
    .preview-video,
    .update-qrcode,
    .email-template-btn {
      &.ant-btn {
        background-color: #ff00dd;
      }
    }
  }
  .postgrid-fallback {
    padding: 10px 0;
  }
  .btn-group {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    background: #2e0249;
    border-radius: 12px;
    .ant-btn {
      width: 40%;
    }
    .btn-login {
      &:hover {
        letter-spacing: 8px;
      }
    }
  }
  .no-template {
    height: 450px;
    width: 600px;
    background-color: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 100px;
    }
  }
  &.broker-digital-template {
    min-height: 720px;
    display: flex;
    align-items: center;
    background-color: #fff;
  }
  .upload-wrapper {
    max-width: ${({ size }) => (size?.width ? size.width + 'px!important' : 'auto')};
  }
  .hidden {
    display: none;
  }
`;
