import React, { useEffect, useRef, useState } from 'react';
import ModalPreviewTemplate from '@/components/custom-modals/modal-preview-template';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Css styles
import {
  CampaignTypeEnum,
  EmailType,
  GlobalModalCredits,
  PostgridEditorActionType,
  PreviewTemplateAction,
  TemplateITemEnum,
} from '@/utils/enum';
import 'swiper/swiper-bundle.css';
import TemplateItem, { scrollTemplateSkeleton } from './TemplateItem';
import { Empty } from 'antd';
import CampaignService from '@/services/campaign';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/reducers/model';
import { PreviewModalProps } from '@/models';
import UserAction from '@/reducers/user/action';
import AppAction from '@/reducers/app/action';
import CreditAction from '@/reducers/credit/action';
import useScrollTemplate from './hooks/useScrollTemplate';
import { AlertTextError } from '@/components/alert/NotificationAlert';
import useDisableBySubscription from '@/hooks/useDisableBySubscription';
import { navigate } from 'gatsby';
import RoutePaths from '@/utils/routes';
import ConfirmModal from '@/components/custom-modals/modal-confirm';
import ModalCreateTemplate from '@/components/custom-modals/modal-create-template';
import { CreateMailPreviewData } from '@/models';
import { DigitalTypeEnum } from '../DigitalCampaignType';
import { AlertTextSuccess } from '@/components/alert/SweetAlert';
import ModalCreateTemplateInfo from '@/components/custom-modals/modal-create-template/create-template-info';
import {
  PostgridTemplateType,
  TemplateViewSide,
  UserPostGridTemplateFallback,
} from '@goldfishcode/first-team-real-estate-sdk/libs/api/postgrid/models';
import Loadable from '@loadable/component';
import { ICustomTemplate } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { checkIsTemplateUsed } from '@/utils/utils';
import { MailingExtra } from '@goldfishcode/first-team-real-estate-sdk/libs/api/tools/variable/models';
import { useCampaignManagerStore } from '../../hooks/useCampaignManager';
import { useShallow } from 'zustand/react/shallow';
import { useUserRole } from '@/hooks/useUserRole';
import { CardTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/handwriting/models';
import { useQueryClient } from '@tanstack/react-query';
import { useSubscriptionSubSetting } from '@/queries/subscription/subSetting';
import ScrollTemplateStyled from './ScrollTemplateStyled';

const EmailEditorModal = Loadable(() => import('@/components/custom-modals/modal-editor-email'));

SwiperCore.use([Navigation]);

export const initialStateDataPreviewModal = {
  visible: false,
  data: {},
  type: TemplateITemEnum.POSTCARD,
} as PreviewModalProps;

export enum ConfirmModalTypeEnum {
  PURCHASE = 'PURCHASE',
  POSTGRID_TEMPLATE_DELETE = 'POSTGRID_TEMPLATE_DELETE',
  EMAIL_TEMPLATE_DELETE = 'EMAIL_TEMPLATE_DELETE',
  HANDWRITTEN_TEMPLATE_DELETE = 'HANDWRITTEN_TEMPLATE_DELETE',
}

interface ScrollTemplateProps {
  data: any[];
  selectedTemplate?: any;
  initSlide?: number;
  isDigitalCampaign?: boolean;
  digitalCampaignType?: DigitalTypeEnum;
  setSelectedTemplate?: (v: any) => void;
  onSelectTemplateToPreview?: (value, previewTemplateType: PreviewTemplateAction) => void;
  onPostgridFallbackTemplateUpdate?: (templateFallback: UserPostGridTemplateFallback) => void;
  campaignType: CampaignTypeEnum;
  mailingItem?: MailingExtra;
  currentActiveSlide?: (slide: number) => void;
  saveFallbackOnEditEmail?: (fallback) => void;
  cardType?: CardTypeEnum;
  isFetchingTemplateDetail?: boolean;
}

const ScrollTemplate: React.FC<ScrollTemplateProps> = ({
  data,
  selectedTemplate,
  setSelectedTemplate,
  initSlide,
  isDigitalCampaign,
  onSelectTemplateToPreview,
  onPostgridFallbackTemplateUpdate,
  campaignType,
  currentActiveSlide,
  saveFallbackOnEditEmail,
  cardType,
  isFetchingTemplateDetail,
}) => {
  const dispatch = useDispatch();
  const swiperRef = useRef<SwiperCore | null>(null);

  const queryClient = useQueryClient();
  const {
    getListPostgridTemplate,
    handleDeletePostgridTemplate,
    handleDeleteHandwrittenTemplate,
    emailTemplateFilter,
    postgridFilter,
  } = useScrollTemplate();
  const mailCreditBalance = useSelector((state: RootState) => state.credit.myCredit?.credit_amount || 0);
  const currentBalance = useSelector((state: RootState) => state.homeCredit?.mySub?.current_balance || 0);
  const isUnlimitedHome = useSelector((state: RootState) => state.auth.myProfile?.unlimited_home);
  const globalTemplate = useSelector((state: RootState) => state?.user?.globalTemplate);
  const emailTemplateList = useSelector((state: RootState) => state?.user?.emailTemplateList);
  const agentID = useSelector((state: RootState) => state?.home?.userAgent?.id);
  const { isManager } = useUserRole();
  const { agentIdCampaign } = useCampaignManagerStore(
    useShallow((state) => ({
      agentIdCampaign: state.agentIdCampaign,
    })),
  );

  const visibleWarningCreditBalanceModal = useSelector(
    (state: RootState) => state.app.visibleWarningCreditBalanceModal,
  );
  const { isDisableBySubscription } = useDisableBySubscription();
  const dataPreviewModal = useSelector((state: RootState) => state.user.dataPreviewModal);
  const campaignTemplate = useSelector((state: RootState) => state?.user?.campaignTemplate);
  const [confirmModalData, setConfirmModalData] = useState<{
    visible: boolean;
    templateId: string;
    type: ConfirmModalTypeEnum;
    templatePrice: string;
    errMsg?: string;
  }>({
    visible: false,
    templateId: '',
    type: ConfirmModalTypeEnum.PURCHASE,
    templatePrice: '',
    errMsg: '',
  });
  const [createLetterModalData, setCreateLetterModalData] = useState<CreateMailPreviewData>({
    visible: false,
    front_url: '',
    back_url: '',
    templateId: '',
    type: PostgridTemplateType.LETTER,
    size: '',
    actionType: PostgridEditorActionType.CLONE,
    defaultFallbacks: {},
    mlsData: {},
  });

  const [emailEditorModalData, setEmailEditorModalData] = useState<{
    visible: boolean;
    selectedTemplate: any;
  }>({
    visible: false,
    selectedTemplate: {},
  });
  const [visibleCreateTemplateNameType, setVisibleCreateTemplateNameType] = useState<{
    visible: boolean;
    actionType: PostgridEditorActionType;
  }>({
    visible: false,
    actionType: PostgridEditorActionType.CLONE,
  });

  const isLoadingPostgridTemplate =
    (campaignType === CampaignTypeEnum.PHYSICAL && campaignTemplate === null) ||
    (campaignTemplate !== null && !data) ||
    (campaignType === CampaignTypeEnum.EMAIL && emailTemplateList === null) ||
    (emailTemplateList !== null && !data);

  const handleUpdateDataPreviewModal = (data: any) => {
    dispatch({
      type: UserAction.UPDATE_PREVIEW_TEMPLATE,
      payload: data,
    });
  };

  const goToFirstSlide = () => {
    const index = initSlide || 0;
    if (swiperRef.current?.realIndex === index) return;
    swiperRef.current?.slideTo(index);
  };
  const handlePurchaseTemplate = async (template_id: string) => {
    try {
      await CampaignService.buyPremiumTemplate(template_id, agentIdCampaign);
      dispatch({
        type: CreditAction.GET_MY_CREDIT,
      });
      await queryClient.invalidateQueries(useSubscriptionSubSetting.queryKey());
      getListPostgridTemplate(undefined, undefined, undefined, undefined, undefined, false, agentIdCampaign ?? '');
      setTimeout(() => {
        AlertTextSuccess('Purchase Succeeded', 'Template was purchased successfully');
      }, 200);
    } catch (error) {
      AlertTextError((error as Error).message, true);
    }
  };

  const handleCreateTemplate = async (
    template: any,
    name: string,
    campaign_type: string,
    actionType: PostgridEditorActionType,
  ) => {
    try {
      const newId = await CampaignService.cloneTemplate(template?.id, {
        name: name,
        campaign_type: campaign_type,
        is_broker_template: actionType === PostgridEditorActionType.CLONE ? false : template?.is_broker_template,
        agent_id: agentIdCampaign || undefined,
      });
      const session = await CampaignService.getEditorSession(newId.id, agentIdCampaign);
      setCreateLetterModalData({
        visible: true,
        front_url: session?.[TemplateViewSide.FRONT],
        back_url: session?.[TemplateViewSide.BACK],
        templateId: newId?.id,
        type: template?.type,
        size: template?.postcard_size,
        actionType: actionType,
        defaultFallbacks: session?.['default_fallbacks'] || {},
        mlsData: session?.['mls_data'] || {},
        mailingListData: session?.['mailing_list_data'] || {},
        rootTemplateId: actionType === PostgridEditorActionType.CLONE ? undefined : template?.id,
      });
    } catch (error) {
      // Do nothing
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    await handleDeletePostgridTemplate(templateId, true, agentIdCampaign || undefined);
  };
  const handleSubmitPurchaseTemplate = () => {
    if (isDisableBySubscription) return navigate(RoutePaths.REQUIRE_SUBSCRIPTION);
    if (globalTemplate?.is_premium && !globalTemplate?.is_purchased) {
      if (
        globalTemplate?.home_credit > 0 &&
        globalTemplate?.credit === 0 &&
        globalTemplate?.home_credit > currentBalance &&
        !isUnlimitedHome
      ) {
        dispatch({
          type: AppAction.VISIBLE_GLOBAL_CREDIT,
          payload: GlobalModalCredits.HOME_CREDITS,
        });
      } else if (globalTemplate?.credit > 0 && globalTemplate?.credit > mailCreditBalance) {
        dispatch({
          type: AppAction.VISIBLE_GLOBAL_CREDIT,
          payload: GlobalModalCredits.CREDITS,
        });
      } else {
        handlePurchaseTemplate(confirmModalData.templateId);
      }
    }
  };

  const deleteCustomTemplate = async (templateId: string) => {
    try {
      await CampaignService.deleteCustomtemplate(templateId, agentIdCampaign || undefined);
      const currentListTemplate = [...emailTemplateList];
      const indexDeleteItem = currentListTemplate?.findIndex((v) => v?.id === templateId);
      currentListTemplate.splice(indexDeleteItem, 1);
      dispatch({
        type: UserAction.EMAIL_TEMPLATE_LIST,
        payload: currentListTemplate,
      });
      setEmailEditorModalData({ visible: false, selectedTemplate: {} });
      setConfirmModalData((prev) => ({ ...prev, visible: false, errMsg: '' }));
    } catch (error) {
      // Do nothing
    }
  };

  const handleCheckPremiumTemplate = (template) => {
    const price =
      template?.home_credit > 0 && template?.credit === 0
        ? `${template?.home_credit} Home Credit(s)`
        : `${template?.credit} Campaign Credit(s)`;
    setConfirmModalData({
      visible: true,
      templateId: template?.id,
      type: ConfirmModalTypeEnum.PURCHASE,
      templatePrice: price,
    });
    dispatch({
      type: UserAction.GLOBAL_TEMPLATE,
      payload: template,
    });
  };

  const handleCheckNoSubscription = () => {
    dispatch({
      type: AppAction.VISIBLE_GLOBAL_UNSUBSCRIBE,
      payload: true,
    });
  };

  const handleFooterCancelClick = () => {
    handleUpdateDataPreviewModal({ ...dataPreviewModal, visible: false, data: {} });
  };

  const handleFooterPreviewClick = (value, previewTemplateType) => {
    if (
      [
        PreviewTemplateAction.CREATE_FROM_TEMPLATE,
        PreviewTemplateAction.DELETE_TEMPLATE,
        PreviewTemplateAction.EDIT_EMAIL_TEMPLATE,
        PreviewTemplateAction.CREATE_OWN_VERSION_EMAIL_TEMPLATE,
        PreviewTemplateAction.DELETE_EMAIL_TEMPLATE,
        PreviewTemplateAction.EDIT_POSTGRID_TEMPLATE,
      ].includes(previewTemplateType)
    )
      handleUpdateDataPreviewModal({ ...dataPreviewModal, visible: false });

    if (previewTemplateType === PreviewTemplateAction.CREATE_FROM_TEMPLATE) {
      if (value.type === PostgridTemplateType.HANDWRITTEN) {
        onSelectTemplateToPreview && onSelectTemplateToPreview(value, previewTemplateType);
      } else {
        setVisibleCreateTemplateNameType((prev) => ({
          ...prev,
          visible: true,
          actionType: PostgridEditorActionType.CLONE,
        }));
        handleUpdateDataPreviewModal({ ...dataPreviewModal, visible: false });
      }
    } else if (previewTemplateType === PreviewTemplateAction.EDIT_POSTGRID_TEMPLATE) {
      if (value.type === PostgridTemplateType.HANDWRITTEN) {
        onSelectTemplateToPreview && onSelectTemplateToPreview(value, previewTemplateType);
      } else {
        setVisibleCreateTemplateNameType((prev) => ({
          ...prev,
          visible: true,
          actionType: PostgridEditorActionType.UPDATE,
        }));
        handleUpdateDataPreviewModal({ ...dataPreviewModal, visible: false });
      }
    } else if (previewTemplateType === PreviewTemplateAction.DELETE_TEMPLATE) {
      if (value.type === PostgridTemplateType.HANDWRITTEN) {
        setConfirmModalData({
          templateId: value?.id,
          visible: true,
          type: ConfirmModalTypeEnum.HANDWRITTEN_TEMPLATE_DELETE,
          templatePrice: '',
        });
      } else {
        setConfirmModalData({
          templateId: value?.id,
          visible: true,
          type: ConfirmModalTypeEnum.POSTGRID_TEMPLATE_DELETE,
          templatePrice: '',
        });
      }
    } else if (previewTemplateType === PreviewTemplateAction.SAVE_CUSTOM_FALLBACK_POSTGRID_TEMPLATE) {
      onPostgridFallbackTemplateUpdate && onPostgridFallbackTemplateUpdate(value);
      handleUpdateDataPreviewModal({ ...dataPreviewModal, visible: false });
    } else if (
      [
        PreviewTemplateAction.EDIT_EMAIL_TEMPLATE,
        PreviewTemplateAction.CREATE_OWN_VERSION_EMAIL_TEMPLATE,
        PreviewTemplateAction.DELETE_EMAIL_TEMPLATE,
      ].includes(previewTemplateType)
    ) {
      if (PreviewTemplateAction.DELETE_EMAIL_TEMPLATE === previewTemplateType) {
        setEmailEditorModalData({ selectedTemplate: value, visible: false });
        setConfirmModalData({
          templateId: value?.id,
          visible: true,
          type: ConfirmModalTypeEnum.EMAIL_TEMPLATE_DELETE,
          templatePrice: '',
        });
      } else {
        setEmailEditorModalData({ selectedTemplate: value, visible: true });
      }
    } else onSelectTemplateToPreview && onSelectTemplateToPreview(value, previewTemplateType);
  };

  const handleConfirmationCancelClick = async () =>
    setConfirmModalData({
      visible: false,
      templateId: '',
      type: ConfirmModalTypeEnum.PURCHASE,
      templatePrice: '',
      errMsg: '',
    });

  const handleConfirmationClick = async () => {
    const effectiveAgentId = isManager ? agentIdCampaign : agentID;
    if (confirmModalData.type === ConfirmModalTypeEnum.PURCHASE) handleSubmitPurchaseTemplate();
    else if (confirmModalData.type === ConfirmModalTypeEnum.POSTGRID_TEMPLATE_DELETE)
      handleDeleteTemplate(confirmModalData.templateId);
    else if (confirmModalData.type === ConfirmModalTypeEnum.HANDWRITTEN_TEMPLATE_DELETE)
      handleDeleteHandwrittenTemplate(confirmModalData.templateId, true, cardType);
    else if (confirmModalData.type === ConfirmModalTypeEnum.EMAIL_TEMPLATE_DELETE) {
      const isTemplateUsed = await checkIsTemplateUsed(emailEditorModalData.selectedTemplate?.id, effectiveAgentId);
      if (!isTemplateUsed) deleteCustomTemplate(emailEditorModalData.selectedTemplate?.id);
      else
        return setConfirmModalData((prev) => ({
          ...prev,
          errMsg: "Template can't be removed because it belongs to other Drip Campaigns",
        }));
    }
    setConfirmModalData({
      visible: false,
      templateId: '',
      type: ConfirmModalTypeEnum.PURCHASE,
      templatePrice: '',
      errMsg: '',
    });
  };

  useEffect(() => {
    if (visibleWarningCreditBalanceModal) {
      handleUpdateDataPreviewModal(initialStateDataPreviewModal);
    }
  }, [visibleWarningCreditBalanceModal]);

  useEffect(() => {
    if (postgridFilter.page === 1 && campaignType === CampaignTypeEnum.PHYSICAL) {
      goToFirstSlide();
    } else if (emailTemplateFilter.page === 1 && campaignType === CampaignTypeEnum.EMAIL) {
      goToFirstSlide();
    } else if (campaignType === CampaignTypeEnum.DIGITAL) {
      goToFirstSlide();
    }
  }, [data, initSlide]);

  return (
    <ScrollTemplateStyled.Wrapper
      isDigitalCampaign={campaignType === CampaignTypeEnum.DIGITAL}
      disabled={(!!isManager && !agentIdCampaign) || Boolean(isFetchingTemplateDetail)}
      className="scroll-wp"
    >
      <Swiper
        allowTouchMove={false}
        slidesPerView={1}
        speed={400}
        centeredSlides={true}
        navigation={true}
        spaceBetween={30}
        className="mySwiper"
        initialSlide={initSlide || 0}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        onSlideChange={(swipper) => currentActiveSlide?.(swipper?.activeIndex)}
      >
        {data && data?.[0]?.length && !isLoadingPostgridTemplate ? (
          data.map((slide) => {
            return (
              <SwiperSlide key={slide[0]?.[0]?.id}>
                <div className={`slide-template ${slide?.length === 9 ? 'height-auto' : ''}`}>
                  {slide &&
                    slide.length > 0 &&
                    slide?.map((template) => {
                      const type = template?.type || template?.digital_ad_type || TemplateITemEnum.POSTCARD;
                      return (
                        <div
                          key={template.id + template.modified}
                          onClick={() => {
                            if ((isManager && !agentIdCampaign) || isFetchingTemplateDetail) return;
                            if (isDisableBySubscription) return handleCheckNoSubscription();

                            if (template?.is_premium && !template?.is_purchased)
                              return handleCheckPremiumTemplate(template);

                            setSelectedTemplate?.(template);
                          }}
                          className={`template-item ${
                            selectedTemplate?.id === template?.id ? 'selected-template' : ''
                          }`}
                        >
                          <TemplateItem
                            type={type}
                            template={template}
                            isDigitalCampaign={isDigitalCampaign}
                            setDataPreviewTemplate={handleUpdateDataPreviewModal}
                            campaignType={campaignType}
                          />
                        </div>
                      );
                    })}
                </div>
              </SwiperSlide>
            );
          })
        ) : isLoadingPostgridTemplate ? (
          <SwiperSlide>
            <div className={`slide-template`}>
              {Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className={`template-item`}>
                  {scrollTemplateSkeleton(campaignType)}
                </div>
              ))}
            </div>
          </SwiperSlide>
        ) : (
          <div className="no-template">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </Swiper>
      <ModalPreviewTemplate
        className="video-preview"
        visible={dataPreviewModal?.visible}
        data={dataPreviewModal?.data}
        onCancel={handleFooterCancelClick}
        htmlString={''}
        onFooterButtonClick={handleFooterPreviewClick}
        isPreviewRawTemplate={true}
      />
      <ConfirmModal
        className="modal-confirm"
        modalWidth={550}
        title={confirmModalData.type === ConfirmModalTypeEnum.PURCHASE ? `Purchase Template?` : `Delete Template?`}
        btnSubmitText="Confirm"
        btnCancelText={'Cancel'}
        visible={confirmModalData.visible}
        closable
        onCancel={handleConfirmationCancelClick}
        onSubmit={handleConfirmationClick}
        body={
          confirmModalData.type === ConfirmModalTypeEnum.PURCHASE ? (
            <p>{`Are you sure you want to purchase ${confirmModalData?.templatePrice} for this template?`}</p>
          ) : null
        }
        errMsg={confirmModalData.errMsg}
      />
      {createLetterModalData.visible && (
        <ModalCreateTemplate
          onCancel={() => {
            setCreateLetterModalData({
              visible: false,
              front_url: '',
              back_url: '',
              templateId: '',
              type: PostgridTemplateType.LETTER,
              size: '',
              actionType: PostgridEditorActionType.CLONE,
            });
          }}
          createLetterData={createLetterModalData}
        />
      )}
      <ModalCreateTemplateInfo
        visible={visibleCreateTemplateNameType.visible}
        onCancel={() => {
          setVisibleCreateTemplateNameType((prev) => ({
            ...prev,
            visible: false,
          }));
        }}
        onConfirm={(name, campaignTypeRes) => {
          handleCreateTemplate(dataPreviewModal.data, name, campaignTypeRes, visibleCreateTemplateNameType?.actionType);
          setVisibleCreateTemplateNameType((prev) => ({
            ...prev,
            visible: false,
          }));
        }}
        editData={
          visibleCreateTemplateNameType?.actionType === PostgridEditorActionType.UPDATE
            ? dataPreviewModal?.data
            : undefined
        }
      />
      <EmailEditorModal
        agentId={isManager ? agentIdCampaign : undefined}
        visible={emailEditorModalData.visible}
        onCancel={() => {
          setEmailEditorModalData((prev) => ({ ...prev, visible: false }));
        }}
        onSave={async (data: any) => {
          setEmailEditorModalData({ visible: false, selectedTemplate: data });
          setSelectedTemplate?.(data);
          saveFallbackOnEditEmail?.(data);
        }}
        //selectedEmailTemplate?.content !==undefined mean it is admin template
        editTemplate={
          {
            ...emailEditorModalData.selectedTemplate,
            name:
              emailEditorModalData.selectedTemplate?.id === EmailType.ENTER_NEW_EMAIL
                ? ''
                : emailEditorModalData.selectedTemplate?.content
                ? ''
                : emailEditorModalData.selectedTemplate?.name,
            custom_title:
              emailEditorModalData.selectedTemplate?.custom_title || emailEditorModalData.selectedTemplate?.title,
            custom_template:
              emailEditorModalData.selectedTemplate?.custom_template || emailEditorModalData.selectedTemplate?.content,
            custom_fallbacks: emailEditorModalData.selectedTemplate?.custom_fallbacks,
            mls_data: emailEditorModalData.selectedTemplate?.mls_data,
            id: emailEditorModalData.selectedTemplate?.content
              ? EmailType.ENTER_NEW_EMAIL
              : emailEditorModalData.selectedTemplate?.id,
          } as ICustomTemplate
        }
        isCreateEmailTemplate={true}
        isUseDisclaimerFallback
      />
    </ScrollTemplateStyled.Wrapper>
  );
};

export default ScrollTemplate;
